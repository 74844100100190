* {
  font-family: 'Barlow Condensed', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

.wlLine {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

input[type="text"] {
  padding: 10px;
  font-size: 20px;
  border: 1px solid #ccc;
  
  margin-right: 10px;
}

button#wlBtn {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button#wlBtn:hover {
  background-color: #45a049;
}

::-webkit-scrollbar {
  width: 10px;
 }

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(51, 203, 241);
}

.linkNew:hover{
  color: #00E6F6;
  cursor: pointer;
}

.linkNew{
  color: #aef7fc;
  transition: 2 ease;
}

.snow {
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
}
.snow:nth-child(1) {
  opacity: 0.7776;
  transform: translate(5.5474vw, -10px) scale(0.3384);
  animation: fall-1 17s -29s linear infinite;
}
@keyframes fall-1 {
  78.881% {
    transform: translate(2.539vw, 78.881vh) scale(0.3384);
  }
  to {
    transform: translate(4.0432vw, 100vh) scale(0.3384);
  }
}
.snow:nth-child(2) {
  opacity: 0.3378;
  transform: translate(20.9383vw, -10px) scale(0.2481);
  animation: fall-2 11s -6s linear infinite;
}
@keyframes fall-2 {
  65.765% {
    transform: translate(30.5368vw, 65.765vh) scale(0.2481);
  }
  to {
    transform: translate(25.73755vw, 100vh) scale(0.2481);
  }
}
.snow:nth-child(3) {
  opacity: 0.9033;
  transform: translate(90.5917vw, -10px) scale(0.9692);
  animation: fall-3 19s -25s linear infinite;
}
@keyframes fall-3 {
  76.704% {
    transform: translate(91.1777vw, 76.704vh) scale(0.9692);
  }
  to {
    transform: translate(90.8847vw, 100vh) scale(0.9692);
  }
}
.snow:nth-child(4) {
  opacity: 0.5632;
  transform: translate(74.679vw, -10px) scale(0.935);
  animation: fall-4 10s -2s linear infinite;
}
@keyframes fall-4 {
  67.984% {
    transform: translate(74.4523vw, 67.984vh) scale(0.935);
  }
  to {
    transform: translate(74.56565vw, 100vh) scale(0.935);
  }
}
.snow:nth-child(5) {
  opacity: 0.6625;
  transform: translate(18.101vw, -10px) scale(0.3854);
  animation: fall-5 27s -14s linear infinite;
}
@keyframes fall-5 {
  72.66% {
    transform: translate(12.7407vw, 72.66vh) scale(0.3854);
  }
  to {
    transform: translate(15.42085vw, 100vh) scale(0.3854);
  }
}
.snow:nth-child(6) {
  opacity: 0.9712;
  transform: translate(42.6188vw, -10px) scale(0.1089);
  animation: fall-6 19s -29s linear infinite;
}
@keyframes fall-6 {
  57.403% {
    transform: translate(52.0438vw, 57.403vh) scale(0.1089);
  }
  to {
    transform: translate(47.3313vw, 100vh) scale(0.1089);
  }
}
.snow:nth-child(7) {
  opacity: 0.9146;
  transform: translate(75.3167vw, -10px) scale(0.2079);
  animation: fall-7 28s -14s linear infinite;
}
@keyframes fall-7 {
  69.119% {
    transform: translate(69.9516vw, 69.119vh) scale(0.2079);
  }
  to {
    transform: translate(72.63415vw, 100vh) scale(0.2079);
  }
}
.snow:nth-child(8) {
  opacity: 0.1921;
  transform: translate(4.186vw, -10px) scale(0.132);
  animation: fall-8 10s -1s linear infinite;
}
@keyframes fall-8 {
  79.578% {
    transform: translate(-1.9678vw, 79.578vh) scale(0.132);
  }
  to {
    transform: translate(1.1091vw, 100vh) scale(0.132);
  }
}
.snow:nth-child(9) {
  opacity: 0.2714;
  transform: translate(66.3755vw, -10px) scale(0.6169);
  animation: fall-9 19s -12s linear infinite;
}
@keyframes fall-9 {
  72.865% {
    transform: translate(70.3249vw, 72.865vh) scale(0.6169);
  }
  to {
    transform: translate(68.3502vw, 100vh) scale(0.6169);
  }
}
.snow:nth-child(10) {
  opacity: 0.1227;
  transform: translate(29.8037vw, -10px) scale(0.2309);
  animation: fall-10 20s -17s linear infinite;
}
@keyframes fall-10 {
  33.711% {
    transform: translate(23.394vw, 33.711vh) scale(0.2309);
  }
  to {
    transform: translate(26.59885vw, 100vh) scale(0.2309);
  }
}
.snow:nth-child(11) {
  opacity: 0.2966;
  transform: translate(53.2259vw, -10px) scale(0.3957);
  animation: fall-11 17s -2s linear infinite;
}
@keyframes fall-11 {
  45.338% {
    transform: translate(61.8441vw, 45.338vh) scale(0.3957);
  }
  to {
    transform: translate(57.535vw, 100vh) scale(0.3957);
  }
}
.snow:nth-child(12) {
  opacity: 0.0105;
  transform: translate(20.599vw, -10px) scale(0.9432);
  animation: fall-12 17s -18s linear infinite;
}
@keyframes fall-12 {
  36.495% {
    transform: translate(25.4668vw, 36.495vh) scale(0.9432);
  }
  to {
    transform: translate(23.0329vw, 100vh) scale(0.9432);
  }
}
.snow:nth-child(13) {
  opacity: 0.502;
  transform: translate(71.5501vw, -10px) scale(0.4538);
  animation: fall-13 20s -22s linear infinite;
}
@keyframes fall-13 {
  53.696% {
    transform: translate(77.7902vw, 53.696vh) scale(0.4538);
  }
  to {
    transform: translate(74.67015vw, 100vh) scale(0.4538);
  }
}
.snow:nth-child(14) {
  opacity: 0.0575;
  transform: translate(11.6897vw, -10px) scale(0.1591);
  animation: fall-14 28s -15s linear infinite;
}
@keyframes fall-14 {
  60.784% {
    transform: translate(15.8212vw, 60.784vh) scale(0.1591);
  }
  to {
    transform: translate(13.75545vw, 100vh) scale(0.1591);
  }
}
.snow:nth-child(15) {
  opacity: 0.6722;
  transform: translate(4.9404vw, -10px) scale(0.2003);
  animation: fall-15 17s -24s linear infinite;
}
@keyframes fall-15 {
  73.61% {
    transform: translate(-4.2378vw, 73.61vh) scale(0.2003);
  }
  to {
    transform: translate(0.3513vw, 100vh) scale(0.2003);
  }
}
.snow:nth-child(16) {
  opacity: 0.7821;
  transform: translate(51.8609vw, -10px) scale(0.553);
  animation: fall-16 23s -11s linear infinite;
}
@keyframes fall-16 {
  60.691% {
    transform: translate(44.8952vw, 60.691vh) scale(0.553);
  }
  to {
    transform: translate(48.37805vw, 100vh) scale(0.553);
  }
}
.snow:nth-child(17) {
  opacity: 0.1427;
  transform: translate(34.2568vw, -10px) scale(0.8414);
  animation: fall-17 18s -22s linear infinite;
}
@keyframes fall-17 {
  62.081% {
    transform: translate(32.3902vw, 62.081vh) scale(0.8414);
  }
  to {
    transform: translate(33.3235vw, 100vh) scale(0.8414);
  }
}
.snow:nth-child(18) {
  opacity: 0.4952;
  transform: translate(18.76vw, -10px) scale(0.8827);
  animation: fall-18 22s -28s linear infinite;
}
@keyframes fall-18 {
  67.93% {
    transform: translate(13.3207vw, 67.93vh) scale(0.8827);
  }
  to {
    transform: translate(16.04035vw, 100vh) scale(0.8827);
  }
}
.snow:nth-child(19) {
  opacity: 0.3991;
  transform: translate(1.292vw, -10px) scale(0.3803);
  animation: fall-19 16s -10s linear infinite;
}
@keyframes fall-19 {
  62.279% {
    transform: translate(-8.1347vw, 62.279vh) scale(0.3803);
  }
  to {
    transform: translate(-3.42135vw, 100vh) scale(0.3803);
  }
}
.snow:nth-child(20) {
  opacity: 0.9981;
  transform: translate(64.0887vw, -10px) scale(0.6675);
  animation: fall-20 19s -1s linear infinite;
}
@keyframes fall-20 {
  36.95% {
    transform: translate(62.5871vw, 36.95vh) scale(0.6675);
  }
  to {
    transform: translate(63.3379vw, 100vh) scale(0.6675);
  }
}
.snow:nth-child(21) {
  opacity: 0.2223;
  transform: translate(39.2006vw, -10px) scale(0.6505);
  animation: fall-21 28s -22s linear infinite;
}
@keyframes fall-21 {
  42.24% {
    transform: translate(29.7235vw, 42.24vh) scale(0.6505);
  }
  to {
    transform: translate(34.46205vw, 100vh) scale(0.6505);
  }
}
.snow:nth-child(22) {
  opacity: 0.4564;
  transform: translate(33.3715vw, -10px) scale(0.0896);
  animation: fall-22 16s -14s linear infinite;
}
@keyframes fall-22 {
  37.03% {
    transform: translate(23.8373vw, 37.03vh) scale(0.0896);
  }
  to {
    transform: translate(28.6044vw, 100vh) scale(0.0896);
  }
}
.snow:nth-child(23) {
  opacity: 0.8146;
  transform: translate(75.0295vw, -10px) scale(0.8917);
  animation: fall-23 28s -3s linear infinite;
}
@keyframes fall-23 {
  55.942% {
    transform: translate(82.8053vw, 55.942vh) scale(0.8917);
  }
  to {
    transform: translate(78.9174vw, 100vh) scale(0.8917);
  }
}
.snow:nth-child(24) {
  opacity: 0.1346;
  transform: translate(62.0479vw, -10px) scale(0.7071);
  animation: fall-24 18s -2s linear infinite;
}
@keyframes fall-24 {
  60.377% {
    transform: translate(55.9967vw, 60.377vh) scale(0.7071);
  }
  to {
    transform: translate(59.0223vw, 100vh) scale(0.7071);
  }
}
.snow:nth-child(25) {
  opacity: 0.4821;
  transform: translate(79.3101vw, -10px) scale(0.1522);
  animation: fall-25 25s -22s linear infinite;
}
@keyframes fall-25 {
  68.073% {
    transform: translate(70.4845vw, 68.073vh) scale(0.1522);
  }
  to {
    transform: translate(74.8973vw, 100vh) scale(0.1522);
  }
}
.snow:nth-child(26) {
  opacity: 0.6424;
  transform: translate(26.0307vw, -10px) scale(0.5989);
  animation: fall-26 13s -28s linear infinite;
}
@keyframes fall-26 {
  55.245% {
    transform: translate(17.1745vw, 55.245vh) scale(0.5989);
  }
  to {
    transform: translate(21.6026vw, 100vh) scale(0.5989);
  }
}
.snow:nth-child(27) {
  opacity: 0.1639;
  transform: translate(73.6281vw, -10px) scale(0.6467);
  animation: fall-27 13s -9s linear infinite;
}
@keyframes fall-27 {
  42.456% {
    transform: translate(79.3918vw, 42.456vh) scale(0.6467);
  }
  to {
    transform: translate(76.50995vw, 100vh) scale(0.6467);
  }
}
.snow:nth-child(28) {
  opacity: 0.1628;
  transform: translate(32.1836vw, -10px) scale(0.1775);
  animation: fall-28 24s -1s linear infinite;
}
@keyframes fall-28 {
  39.359% {
    transform: translate(33.3594vw, 39.359vh) scale(0.1775);
  }
  to {
    transform: translate(32.7715vw, 100vh) scale(0.1775);
  }
}
.snow:nth-child(29) {
  opacity: 0.9957;
  transform: translate(59.4888vw, -10px) scale(0.5349);
  animation: fall-29 24s -29s linear infinite;
}
@keyframes fall-29 {
  64.459% {
    transform: translate(61.8128vw, 64.459vh) scale(0.5349);
  }
  to {
    transform: translate(60.6508vw, 100vh) scale(0.5349);
  }
}
.snow:nth-child(30) {
  opacity: 0.8483;
  transform: translate(36.6791vw, -10px) scale(0.4295);
  animation: fall-30 25s -1s linear infinite;
}
@keyframes fall-30 {
  57.326% {
    transform: translate(45.5018vw, 57.326vh) scale(0.4295);
  }
  to {
    transform: translate(41.09045vw, 100vh) scale(0.4295);
  }
}
.snow:nth-child(31) {
  opacity: 0.9147;
  transform: translate(42.0538vw, -10px) scale(0.9147);
  animation: fall-31 22s -22s linear infinite;
}
@keyframes fall-31 {
  46.778% {
    transform: translate(35.6235vw, 46.778vh) scale(0.9147);
  }
  to {
    transform: translate(38.83865vw, 100vh) scale(0.9147);
  }
}
.snow:nth-child(32) {
  opacity: 0.5068;
  transform: translate(84.8233vw, -10px) scale(0.7467);
  animation: fall-32 26s -18s linear infinite;
}
@keyframes fall-32 {
  45.542% {
    transform: translate(83.6vw, 45.542vh) scale(0.7467);
  }
  to {
    transform: translate(84.21165vw, 100vh) scale(0.7467);
  }
}
.snow:nth-child(33) {
  opacity: 0.8323;
  transform: translate(92.003vw, -10px) scale(0.0441);
  animation: fall-33 19s -8s linear infinite;
}
@keyframes fall-33 {
  38.235% {
    transform: translate(97.3944vw, 38.235vh) scale(0.0441);
  }
  to {
    transform: translate(94.6987vw, 100vh) scale(0.0441);
  }
}
.snow:nth-child(34) {
  opacity: 0.3678;
  transform: translate(71.212vw, -10px) scale(0.7446);
  animation: fall-34 18s -12s linear infinite;
}
@keyframes fall-34 {
  60.491% {
    transform: translate(73.0936vw, 60.491vh) scale(0.7446);
  }
  to {
    transform: translate(72.1528vw, 100vh) scale(0.7446);
  }
}
.snow:nth-child(35) {
  opacity: 0.7892;
  transform: translate(49.4384vw, -10px) scale(0.2686);
  animation: fall-35 11s -26s linear infinite;
}
@keyframes fall-35 {
  66.675% {
    transform: translate(47.0335vw, 66.675vh) scale(0.2686);
  }
  to {
    transform: translate(48.23595vw, 100vh) scale(0.2686);
  }
}
.snow:nth-child(36) {
  opacity: 0.3484;
  transform: translate(29.0143vw, -10px) scale(0.1731);
  animation: fall-36 28s -8s linear infinite;
}
@keyframes fall-36 {
  35.346% {
    transform: translate(25.2798vw, 35.346vh) scale(0.1731);
  }
  to {
    transform: translate(27.14705vw, 100vh) scale(0.1731);
  }
}
.snow:nth-child(37) {
  opacity: 0.0114;
  transform: translate(92.5182vw, -10px) scale(0.3472);
  animation: fall-37 27s -24s linear infinite;
}
@keyframes fall-37 {
  62.271% {
    transform: translate(97.818vw, 62.271vh) scale(0.3472);
  }
  to {
    transform: translate(95.1681vw, 100vh) scale(0.3472);
  }
}
.snow:nth-child(38) {
  opacity: 0.3394;
  transform: translate(0.2234vw, -10px) scale(0.8845);
  animation: fall-38 11s -24s linear infinite;
}
@keyframes fall-38 {
  51.23% {
    transform: translate(-1.4376vw, 51.23vh) scale(0.8845);
  }
  to {
    transform: translate(-0.6071vw, 100vh) scale(0.8845);
  }
}
.snow:nth-child(39) {
  opacity: 0.6256;
  transform: translate(99.6392vw, -10px) scale(0.9895);
  animation: fall-39 14s -6s linear infinite;
}
@keyframes fall-39 {
  59.269% {
    transform: translate(102.3434vw, 59.269vh) scale(0.9895);
  }
  to {
    transform: translate(100.9913vw, 100vh) scale(0.9895);
  }
}
.snow:nth-child(40) {
  opacity: 0.9328;
  transform: translate(0.6213vw, -10px) scale(0.9776);
  animation: fall-40 21s -14s linear infinite;
}
@keyframes fall-40 {
  68.008% {
    transform: translate(5.3474vw, 68.008vh) scale(0.9776);
  }
  to {
    transform: translate(2.98435vw, 100vh) scale(0.9776);
  }
}
.snow:nth-child(41) {
  opacity: 0.6292;
  transform: translate(54.6847vw, -10px) scale(0.8915);
  animation: fall-41 29s -21s linear infinite;
}
@keyframes fall-41 {
  38.943% {
    transform: translate(56.3882vw, 38.943vh) scale(0.8915);
  }
  to {
    transform: translate(55.53645vw, 100vh) scale(0.8915);
  }
}
.snow:nth-child(42) {
  opacity: 0.4819;
  transform: translate(38.4928vw, -10px) scale(0.3827);
  animation: fall-42 17s -15s linear infinite;
}
@keyframes fall-42 {
  72.135% {
    transform: translate(38.7576vw, 72.135vh) scale(0.3827);
  }
  to {
    transform: translate(38.6252vw, 100vh) scale(0.3827);
  }
}
.snow:nth-child(43) {
  opacity: 0.2089;
  transform: translate(32.0463vw, -10px) scale(0.7674);
  animation: fall-43 26s -25s linear infinite;
}
@keyframes fall-43 {
  61.165% {
    transform: translate(23.8322vw, 61.165vh) scale(0.7674);
  }
  to {
    transform: translate(27.93925vw, 100vh) scale(0.7674);
  }
}
.snow:nth-child(44) {
  opacity: 0.1451;
  transform: translate(62.0864vw, -10px) scale(0.2488);
  animation: fall-44 29s -15s linear infinite;
}
@keyframes fall-44 {
  64.259% {
    transform: translate(71.2633vw, 64.259vh) scale(0.2488);
  }
  to {
    transform: translate(66.67485vw, 100vh) scale(0.2488);
  }
}
.snow:nth-child(45) {
  opacity: 0.6859;
  transform: translate(16.1368vw, -10px) scale(0.6653);
  animation: fall-45 21s -23s linear infinite;
}
@keyframes fall-45 {
  44.318% {
    transform: translate(11.9416vw, 44.318vh) scale(0.6653);
  }
  to {
    transform: translate(14.0392vw, 100vh) scale(0.6653);
  }
}
.snow:nth-child(46) {
  opacity: 0.4236;
  transform: translate(99.7324vw, -10px) scale(0.8406);
  animation: fall-46 29s -29s linear infinite;
}
@keyframes fall-46 {
  70.321% {
    transform: translate(93.778vw, 70.321vh) scale(0.8406);
  }
  to {
    transform: translate(96.7552vw, 100vh) scale(0.8406);
  }
}
.snow:nth-child(47) {
  opacity: 0.229;
  transform: translate(53.9875vw, -10px) scale(0.2711);
  animation: fall-47 25s -30s linear infinite;
}
@keyframes fall-47 {
  34.91% {
    transform: translate(50.9649vw, 34.91vh) scale(0.2711);
  }
  to {
    transform: translate(52.4762vw, 100vh) scale(0.2711);
  }
}
.snow:nth-child(48) {
  opacity: 0.7299;
  transform: translate(17.2798vw, -10px) scale(0.2045);
  animation: fall-48 27s -7s linear infinite;
}
@keyframes fall-48 {
  47.14% {
    transform: translate(11.9277vw, 47.14vh) scale(0.2045);
  }
  to {
    transform: translate(14.60375vw, 100vh) scale(0.2045);
  }
}
.snow:nth-child(49) {
  opacity: 0.3548;
  transform: translate(62.7861vw, -10px) scale(0.5825);
  animation: fall-49 22s -11s linear infinite;
}
@keyframes fall-49 {
  78.852% {
    transform: translate(64.7602vw, 78.852vh) scale(0.5825);
  }
  to {
    transform: translate(63.77315vw, 100vh) scale(0.5825);
  }
}
.snow:nth-child(50) {
  opacity: 0.2016;
  transform: translate(22.5303vw, -10px) scale(0.6023);
  animation: fall-50 13s -20s linear infinite;
}
@keyframes fall-50 {
  72.36% {
    transform: translate(29.0361vw, 72.36vh) scale(0.6023);
  }
  to {
    transform: translate(25.7832vw, 100vh) scale(0.6023);
  }
}
.snow:nth-child(51) {
  opacity: 0.4724;
  transform: translate(70.1117vw, -10px) scale(0.0485);
  animation: fall-51 15s -20s linear infinite;
}
@keyframes fall-51 {
  48.542% {
    transform: translate(71.4949vw, 48.542vh) scale(0.0485);
  }
  to {
    transform: translate(70.8033vw, 100vh) scale(0.0485);
  }
}
.snow:nth-child(52) {
  opacity: 0.5637;
  transform: translate(84.4678vw, -10px) scale(0.3984);
  animation: fall-52 17s -19s linear infinite;
}
@keyframes fall-52 {
  51.426% {
    transform: translate(86.1422vw, 51.426vh) scale(0.3984);
  }
  to {
    transform: translate(85.305vw, 100vh) scale(0.3984);
  }
}
.snow:nth-child(53) {
  opacity: 0.3712;
  transform: translate(37.4075vw, -10px) scale(0.0469);
  animation: fall-53 10s -2s linear infinite;
}
@keyframes fall-53 {
  48.995% {
    transform: translate(37.3308vw, 48.995vh) scale(0.0469);
  }
  to {
    transform: translate(37.36915vw, 100vh) scale(0.0469);
  }
}
.snow:nth-child(54) {
  opacity: 0.678;
  transform: translate(39.3934vw, -10px) scale(0.7112);
  animation: fall-54 23s -11s linear infinite;
}
@keyframes fall-54 {
  78.917% {
    transform: translate(47.6178vw, 78.917vh) scale(0.7112);
  }
  to {
    transform: translate(43.5056vw, 100vh) scale(0.7112);
  }
}
.snow:nth-child(55) {
  opacity: 0.9247;
  transform: translate(2.1302vw, -10px) scale(0.1178);
  animation: fall-55 24s -23s linear infinite;
}
@keyframes fall-55 {
  78.615% {
    transform: translate(-0.4808vw, 78.615vh) scale(0.1178);
  }
  to {
    transform: translate(0.8247vw, 100vh) scale(0.1178);
  }
}
.snow:nth-child(56) {
  opacity: 0.743;
  transform: translate(89.8551vw, -10px) scale(0.8415);
  animation: fall-56 25s -14s linear infinite;
}
@keyframes fall-56 {
  43.25% {
    transform: translate(83.3118vw, 43.25vh) scale(0.8415);
  }
  to {
    transform: translate(86.58345vw, 100vh) scale(0.8415);
  }
}
.snow:nth-child(57) {
  opacity: 0.3458;
  transform: translate(83.0763vw, -10px) scale(0.0348);
  animation: fall-57 13s -9s linear infinite;
}
@keyframes fall-57 {
  43.423% {
    transform: translate(87.6615vw, 43.423vh) scale(0.0348);
  }
  to {
    transform: translate(85.3689vw, 100vh) scale(0.0348);
  }
}
.snow:nth-child(58) {
  opacity: 0.8191;
  transform: translate(77.1241vw, -10px) scale(0.2339);
  animation: fall-58 30s -4s linear infinite;
}
@keyframes fall-58 {
  63.888% {
    transform: translate(71.5186vw, 63.888vh) scale(0.2339);
  }
  to {
    transform: translate(74.32135vw, 100vh) scale(0.2339);
  }
}
.snow:nth-child(59) {
  opacity: 0.2644;
  transform: translate(71.8626vw, -10px) scale(0.0185);
  animation: fall-59 20s -13s linear infinite;
}
@keyframes fall-59 {
  79.686% {
    transform: translate(70.4919vw, 79.686vh) scale(0.0185);
  }
  to {
    transform: translate(71.17725vw, 100vh) scale(0.0185);
  }
}
.snow:nth-child(60) {
  opacity: 0.6581;
  transform: translate(16.3486vw, -10px) scale(0.3133);
  animation: fall-60 11s -4s linear infinite;
}
@keyframes fall-60 {
  37.514% {
    transform: translate(25.2697vw, 37.514vh) scale(0.3133);
  }
  to {
    transform: translate(20.80915vw, 100vh) scale(0.3133);
  }
}
.snow:nth-child(61) {
  opacity: 0.5223;
  transform: translate(71.3804vw, -10px) scale(0.614);
  animation: fall-61 26s -16s linear infinite;
}
@keyframes fall-61 {
  31.387% {
    transform: translate(64.2892vw, 31.387vh) scale(0.614);
  }
  to {
    transform: translate(67.8348vw, 100vh) scale(0.614);
  }
}
.snow:nth-child(62) {
  opacity: 0.7366;
  transform: translate(77.1702vw, -10px) scale(0.372);
  animation: fall-62 14s -25s linear infinite;
}
@keyframes fall-62 {
  47.569% {
    transform: translate(71.08vw, 47.569vh) scale(0.372);
  }
  to {
    transform: translate(74.1251vw, 100vh) scale(0.372);
  }
}
.snow:nth-child(63) {
  opacity: 0.8351;
  transform: translate(15.6674vw, -10px) scale(0.3527);
  animation: fall-63 16s -20s linear infinite;
}
@keyframes fall-63 {
  36.018% {
    transform: translate(21.7378vw, 36.018vh) scale(0.3527);
  }
  to {
    transform: translate(18.7026vw, 100vh) scale(0.3527);
  }
}
.snow:nth-child(64) {
  opacity: 0.8967;
  transform: translate(27.4956vw, -10px) scale(0.1158);
  animation: fall-64 22s -19s linear infinite;
}
@keyframes fall-64 {
  49.214% {
    transform: translate(21.8618vw, 49.214vh) scale(0.1158);
  }
  to {
    transform: translate(24.6787vw, 100vh) scale(0.1158);
  }
}
.snow:nth-child(65) {
  opacity: 0.5331;
  transform: translate(19.3507vw, -10px) scale(0.9159);
  animation: fall-65 14s -11s linear infinite;
}
@keyframes fall-65 {
  34.215% {
    transform: translate(13.2662vw, 34.215vh) scale(0.9159);
  }
  to {
    transform: translate(16.30845vw, 100vh) scale(0.9159);
  }
}
.snow:nth-child(66) {
  opacity: 0.1726;
  transform: translate(3.3916vw, -10px) scale(0.7289);
  animation: fall-66 22s -22s linear infinite;
}
@keyframes fall-66 {
  78.106% {
    transform: translate(11.5555vw, 78.106vh) scale(0.7289);
  }
  to {
    transform: translate(7.47355vw, 100vh) scale(0.7289);
  }
}
.snow:nth-child(67) {
  opacity: 0.1815;
  transform: translate(65.1077vw, -10px) scale(0.8477);
  animation: fall-67 14s -6s linear infinite;
}
@keyframes fall-67 {
  49.903% {
    transform: translate(56.1217vw, 49.903vh) scale(0.8477);
  }
  to {
    transform: translate(60.6147vw, 100vh) scale(0.8477);
  }
}
.snow:nth-child(68) {
  opacity: 0.0924;
  transform: translate(79.0288vw, -10px) scale(0.6829);
  animation: fall-68 20s -28s linear infinite;
}
@keyframes fall-68 {
  54.195% {
    transform: translate(80.1495vw, 54.195vh) scale(0.6829);
  }
  to {
    transform: translate(79.58915vw, 100vh) scale(0.6829);
  }
}
.snow:nth-child(69) {
  opacity: 0.6882;
  transform: translate(7.8706vw, -10px) scale(0.0683);
  animation: fall-69 30s -5s linear infinite;
}
@keyframes fall-69 {
  43.599% {
    transform: translate(16.5627vw, 43.599vh) scale(0.0683);
  }
  to {
    transform: translate(12.21665vw, 100vh) scale(0.0683);
  }
}
.snow:nth-child(70) {
  opacity: 0.3869;
  transform: translate(28.4992vw, -10px) scale(0.1055);
  animation: fall-70 24s -7s linear infinite;
}
@keyframes fall-70 {
  40.831% {
    transform: translate(30.3786vw, 40.831vh) scale(0.1055);
  }
  to {
    transform: translate(29.4389vw, 100vh) scale(0.1055);
  }
}
.snow:nth-child(71) {
  opacity: 0.4827;
  transform: translate(17.9012vw, -10px) scale(0.3307);
  animation: fall-71 29s -11s linear infinite;
}
@keyframes fall-71 {
  52.1% {
    transform: translate(15.2127vw, 52.1vh) scale(0.3307);
  }
  to {
    transform: translate(16.55695vw, 100vh) scale(0.3307);
  }
}
.snow:nth-child(72) {
  opacity: 0.5823;
  transform: translate(75.7434vw, -10px) scale(0.6468);
  animation: fall-72 21s -10s linear infinite;
}
@keyframes fall-72 {
  73.535% {
    transform: translate(82.773vw, 73.535vh) scale(0.6468);
  }
  to {
    transform: translate(79.2582vw, 100vh) scale(0.6468);
  }
}
.snow:nth-child(73) {
  opacity: 0.6268;
  transform: translate(73.5773vw, -10px) scale(0.6096);
  animation: fall-73 12s -3s linear infinite;
}
@keyframes fall-73 {
  76.73% {
    transform: translate(65.2048vw, 76.73vh) scale(0.6096);
  }
  to {
    transform: translate(69.39105vw, 100vh) scale(0.6096);
  }
}
.snow:nth-child(74) {
  opacity: 0.8109;
  transform: translate(91.9475vw, -10px) scale(0.9717);
  animation: fall-74 26s -22s linear infinite;
}
@keyframes fall-74 {
  55.442% {
    transform: translate(96.7214vw, 55.442vh) scale(0.9717);
  }
  to {
    transform: translate(94.33445vw, 100vh) scale(0.9717);
  }
}
.snow:nth-child(75) {
  opacity: 0.6628;
  transform: translate(65.6708vw, -10px) scale(0.071);
  animation: fall-75 15s -24s linear infinite;
}
@keyframes fall-75 {
  57.496% {
    transform: translate(58.754vw, 57.496vh) scale(0.071);
  }
  to {
    transform: translate(62.2124vw, 100vh) scale(0.071);
  }
}
.snow:nth-child(76) {
  opacity: 0.94;
  transform: translate(80.864vw, -10px) scale(0.8887);
  animation: fall-76 12s -30s linear infinite;
}
@keyframes fall-76 {
  45.052% {
    transform: translate(72.1719vw, 45.052vh) scale(0.8887);
  }
  to {
    transform: translate(76.51795vw, 100vh) scale(0.8887);
  }
}
.snow:nth-child(77) {
  opacity: 0.0347;
  transform: translate(36.3827vw, -10px) scale(0.149);
  animation: fall-77 24s -28s linear infinite;
}
@keyframes fall-77 {
  46.264% {
    transform: translate(39.1739vw, 46.264vh) scale(0.149);
  }
  to {
    transform: translate(37.7783vw, 100vh) scale(0.149);
  }
}
.snow:nth-child(78) {
  opacity: 0.5677;
  transform: translate(46.2919vw, -10px) scale(0.7811);
  animation: fall-78 18s -18s linear infinite;
}
@keyframes fall-78 {
  51.058% {
    transform: translate(45.1431vw, 51.058vh) scale(0.7811);
  }
  to {
    transform: translate(45.7175vw, 100vh) scale(0.7811);
  }
}
.snow:nth-child(79) {
  opacity: 0.3603;
  transform: translate(96.0913vw, -10px) scale(0.8055);
  animation: fall-79 17s -28s linear infinite;
}
@keyframes fall-79 {
  72.719% {
    transform: translate(103.3012vw, 72.719vh) scale(0.8055);
  }
  to {
    transform: translate(99.69625vw, 100vh) scale(0.8055);
  }
}
.snow:nth-child(80) {
  opacity: 0.3796;
  transform: translate(14.699vw, -10px) scale(0.3012);
  animation: fall-80 23s -27s linear infinite;
}
@keyframes fall-80 {
  53.312% {
    transform: translate(10.7952vw, 53.312vh) scale(0.3012);
  }
  to {
    transform: translate(12.7471vw, 100vh) scale(0.3012);
  }
}
.snow:nth-child(81) {
  opacity: 0.5101;
  transform: translate(94.7583vw, -10px) scale(0.9334);
  animation: fall-81 24s -3s linear infinite;
}
@keyframes fall-81 {
  35.53% {
    transform: translate(88.282vw, 35.53vh) scale(0.9334);
  }
  to {
    transform: translate(91.52015vw, 100vh) scale(0.9334);
  }
}
.snow:nth-child(82) {
  opacity: 0.2248;
  transform: translate(15.8359vw, -10px) scale(0.764);
  animation: fall-82 26s -16s linear infinite;
}
@keyframes fall-82 {
  53.007% {
    transform: translate(13.6082vw, 53.007vh) scale(0.764);
  }
  to {
    transform: translate(14.72205vw, 100vh) scale(0.764);
  }
}
.snow:nth-child(83) {
  opacity: 0.9463;
  transform: translate(26.1267vw, -10px) scale(0.0926);
  animation: fall-83 23s -30s linear infinite;
}
@keyframes fall-83 {
  73.683% {
    transform: translate(32.8068vw, 73.683vh) scale(0.0926);
  }
  to {
    transform: translate(29.46675vw, 100vh) scale(0.0926);
  }
}
.snow:nth-child(84) {
  opacity: 0.483;
  transform: translate(15.8529vw, -10px) scale(0.4931);
  animation: fall-84 24s -8s linear infinite;
}
@keyframes fall-84 {
  33.118% {
    transform: translate(13.9014vw, 33.118vh) scale(0.4931);
  }
  to {
    transform: translate(14.87715vw, 100vh) scale(0.4931);
  }
}
.snow:nth-child(85) {
  opacity: 0.2072;
  transform: translate(45.6748vw, -10px) scale(0.2091);
  animation: fall-85 19s -27s linear infinite;
}
@keyframes fall-85 {
  46.06% {
    transform: translate(52.8017vw, 46.06vh) scale(0.2091);
  }
  to {
    transform: translate(49.23825vw, 100vh) scale(0.2091);
  }
}
.snow:nth-child(86) {
  opacity: 0.4912;
  transform: translate(1.3765vw, -10px) scale(0.8025);
  animation: fall-86 22s -9s linear infinite;
}
@keyframes fall-86 {
  44.912% {
    transform: translate(5.1018vw, 44.912vh) scale(0.8025);
  }
  to {
    transform: translate(3.23915vw, 100vh) scale(0.8025);
  }
}
.snow:nth-child(87) {
  opacity: 0.7173;
  transform: translate(5.5575vw, -10px) scale(0.7633);
  animation: fall-87 26s -20s linear infinite;
}
@keyframes fall-87 {
  38.629% {
    transform: translate(12.4053vw, 38.629vh) scale(0.7633);
  }
  to {
    transform: translate(8.9814vw, 100vh) scale(0.7633);
  }
}
.snow:nth-child(88) {
  opacity: 0.7086;
  transform: translate(50.6736vw, -10px) scale(0.0743);
  animation: fall-88 29s -6s linear infinite;
}
@keyframes fall-88 {
  34.617% {
    transform: translate(58.3543vw, 34.617vh) scale(0.0743);
  }
  to {
    transform: translate(54.51395vw, 100vh) scale(0.0743);
  }
}
.snow:nth-child(89) {
  opacity: 0.3388;
  transform: translate(34.4676vw, -10px) scale(0.621);
  animation: fall-89 30s -15s linear infinite;
}
@keyframes fall-89 {
  31.673% {
    transform: translate(25.6769vw, 31.673vh) scale(0.621);
  }
  to {
    transform: translate(30.07225vw, 100vh) scale(0.621);
  }
}
.snow:nth-child(90) {
  opacity: 0.1544;
  transform: translate(92.4978vw, -10px) scale(0.1431);
  animation: fall-90 13s -5s linear infinite;
}
@keyframes fall-90 {
  74.989% {
    transform: translate(87.1737vw, 74.989vh) scale(0.1431);
  }
  to {
    transform: translate(89.83575vw, 100vh) scale(0.1431);
  }
}
.snow:nth-child(91) {
  opacity: 0.14;
  transform: translate(8.4784vw, -10px) scale(0.7704);
  animation: fall-91 21s -5s linear infinite;
}
@keyframes fall-91 {
  45.562% {
    transform: translate(7.0658vw, 45.562vh) scale(0.7704);
  }
  to {
    transform: translate(7.7721vw, 100vh) scale(0.7704);
  }
}
.snow:nth-child(92) {
  opacity: 0.8636;
  transform: translate(9.5531vw, -10px) scale(0.9793);
  animation: fall-92 11s -5s linear infinite;
}
@keyframes fall-92 {
  50.49% {
    transform: translate(15.4862vw, 50.49vh) scale(0.9793);
  }
  to {
    transform: translate(12.51965vw, 100vh) scale(0.9793);
  }
}
.snow:nth-child(93) {
  opacity: 0.0416;
  transform: translate(0.4702vw, -10px) scale(0.0547);
  animation: fall-93 19s -18s linear infinite;
}
@keyframes fall-93 {
  55.011% {
    transform: translate(-8.2379vw, 55.011vh) scale(0.0547);
  }
  to {
    transform: translate(-3.88385vw, 100vh) scale(0.0547);
  }
}
.snow:nth-child(94) {
  opacity: 0.2783;
  transform: translate(16.8142vw, -10px) scale(0.6002);
  animation: fall-94 21s -5s linear infinite;
}
@keyframes fall-94 {
  73.758% {
    transform: translate(16.9497vw, 73.758vh) scale(0.6002);
  }
  to {
    transform: translate(16.88195vw, 100vh) scale(0.6002);
  }
}
.snow:nth-child(95) {
  opacity: 0.0729;
  transform: translate(85.5637vw, -10px) scale(0.8928);
  animation: fall-95 16s -5s linear infinite;
}
@keyframes fall-95 {
  75.842% {
    transform: translate(85.6456vw, 75.842vh) scale(0.8928);
  }
  to {
    transform: translate(85.60465vw, 100vh) scale(0.8928);
  }
}
.snow:nth-child(96) {
  opacity: 0.1421;
  transform: translate(14.7956vw, -10px) scale(0.9824);
  animation: fall-96 19s -26s linear infinite;
}
@keyframes fall-96 {
  57.875% {
    transform: translate(13.0498vw, 57.875vh) scale(0.9824);
  }
  to {
    transform: translate(13.9227vw, 100vh) scale(0.9824);
  }
}
.snow:nth-child(97) {
  opacity: 0.8167;
  transform: translate(72.5082vw, -10px) scale(0.6474);
  animation: fall-97 30s -23s linear infinite;
}
@keyframes fall-97 {
  54.367% {
    transform: translate(65.8816vw, 54.367vh) scale(0.6474);
  }
  to {
    transform: translate(69.1949vw, 100vh) scale(0.6474);
  }
}
.snow:nth-child(98) {
  opacity: 0.1528;
  transform: translate(85.8559vw, -10px) scale(0.5983);
  animation: fall-98 22s -6s linear infinite;
}
@keyframes fall-98 {
  40.701% {
    transform: translate(90.5783vw, 40.701vh) scale(0.5983);
  }
  to {
    transform: translate(88.2171vw, 100vh) scale(0.5983);
  }
}
.snow:nth-child(99) {
  opacity: 0.9877;
  transform: translate(92.5037vw, -10px) scale(0.7766);
  animation: fall-99 12s -7s linear infinite;
}
@keyframes fall-99 {
  49.983% {
    transform: translate(88.5018vw, 49.983vh) scale(0.7766);
  }
  to {
    transform: translate(90.50275vw, 100vh) scale(0.7766);
  }
}
.snow:nth-child(100) {
  opacity: 0.9264;
  transform: translate(86.4036vw, -10px) scale(0.2129);
  animation: fall-100 15s -5s linear infinite;
}
@keyframes fall-100 {
  66.997% {
    transform: translate(76.9656vw, 66.997vh) scale(0.2129);
  }
  to {
    transform: translate(81.6846vw, 100vh) scale(0.2129);
  }
}
.snow:nth-child(101) {
  opacity: 0.6182;
  transform: translate(80.2683vw, -10px) scale(0.5921);
  animation: fall-101 10s -12s linear infinite;
}
@keyframes fall-101 {
  69.864% {
    transform: translate(84.0649vw, 69.864vh) scale(0.5921);
  }
  to {
    transform: translate(82.1666vw, 100vh) scale(0.5921);
  }
}
.snow:nth-child(102) {
  opacity: 0.3384;
  transform: translate(6.9817vw, -10px) scale(0.0622);
  animation: fall-102 15s -17s linear infinite;
}
@keyframes fall-102 {
  70.085% {
    transform: translate(-2.21vw, 70.085vh) scale(0.0622);
  }
  to {
    transform: translate(2.38585vw, 100vh) scale(0.0622);
  }
}
.snow:nth-child(103) {
  opacity: 0.1653;
  transform: translate(47.7294vw, -10px) scale(0.3494);
  animation: fall-103 23s -29s linear infinite;
}
@keyframes fall-103 {
  65.356% {
    transform: translate(38.5971vw, 65.356vh) scale(0.3494);
  }
  to {
    transform: translate(43.16325vw, 100vh) scale(0.3494);
  }
}
.snow:nth-child(104) {
  opacity: 0.5935;
  transform: translate(47.7729vw, -10px) scale(0.1159);
  animation: fall-104 12s -5s linear infinite;
}
@keyframes fall-104 {
  52.349% {
    transform: translate(50.9633vw, 52.349vh) scale(0.1159);
  }
  to {
    transform: translate(49.3681vw, 100vh) scale(0.1159);
  }
}
.snow:nth-child(105) {
  opacity: 0.0437;
  transform: translate(18.34vw, -10px) scale(0.8057);
  animation: fall-105 13s -19s linear infinite;
}
@keyframes fall-105 {
  60.9% {
    transform: translate(8.8825vw, 60.9vh) scale(0.8057);
  }
  to {
    transform: translate(13.61125vw, 100vh) scale(0.8057);
  }
}
.snow:nth-child(106) {
  opacity: 0.4928;
  transform: translate(66.5815vw, -10px) scale(0.9577);
  animation: fall-106 27s -26s linear infinite;
}
@keyframes fall-106 {
  65.678% {
    transform: translate(69.5581vw, 65.678vh) scale(0.9577);
  }
  to {
    transform: translate(68.0698vw, 100vh) scale(0.9577);
  }
}
.snow:nth-child(107) {
  opacity: 0.2257;
  transform: translate(78.8199vw, -10px) scale(0.0926);
  animation: fall-107 28s -4s linear infinite;
}
@keyframes fall-107 {
  70.699% {
    transform: translate(81.4228vw, 70.699vh) scale(0.0926);
  }
  to {
    transform: translate(80.12135vw, 100vh) scale(0.0926);
  }
}
.snow:nth-child(108) {
  opacity: 0.5107;
  transform: translate(2.8783vw, -10px) scale(0.214);
  animation: fall-108 25s -26s linear infinite;
}
@keyframes fall-108 {
  55.401% {
    transform: translate(-3.0546vw, 55.401vh) scale(0.214);
  }
  to {
    transform: translate(-0.08815vw, 100vh) scale(0.214);
  }
}
.snow:nth-child(109) {
  opacity: 0.3252;
  transform: translate(89.607vw, -10px) scale(0.2279);
  animation: fall-109 23s -6s linear infinite;
}
@keyframes fall-109 {
  72.334% {
    transform: translate(96.4433vw, 72.334vh) scale(0.2279);
  }
  to {
    transform: translate(93.02515vw, 100vh) scale(0.2279);
  }
}
.snow:nth-child(110) {
  opacity: 0.7039;
  transform: translate(59.0169vw, -10px) scale(0.0036);
  animation: fall-110 18s -14s linear infinite;
}
@keyframes fall-110 {
  42.21% {
    transform: translate(64.8883vw, 42.21vh) scale(0.0036);
  }
  to {
    transform: translate(61.9526vw, 100vh) scale(0.0036);
  }
}
.snow:nth-child(111) {
  opacity: 0.3331;
  transform: translate(88.8233vw, -10px) scale(0.0662);
  animation: fall-111 15s -24s linear infinite;
}
@keyframes fall-111 {
  65.055% {
    transform: translate(84.9824vw, 65.055vh) scale(0.0662);
  }
  to {
    transform: translate(86.90285vw, 100vh) scale(0.0662);
  }
}
.snow:nth-child(112) {
  opacity: 0.3673;
  transform: translate(65.8066vw, -10px) scale(0.4256);
  animation: fall-112 12s -5s linear infinite;
}
@keyframes fall-112 {
  60.068% {
    transform: translate(58.7937vw, 60.068vh) scale(0.4256);
  }
  to {
    transform: translate(62.30015vw, 100vh) scale(0.4256);
  }
}
.snow:nth-child(113) {
  opacity: 0.9027;
  transform: translate(81.6963vw, -10px) scale(0.9129);
  animation: fall-113 12s -13s linear infinite;
}
@keyframes fall-113 {
  61.181% {
    transform: translate(84.1594vw, 61.181vh) scale(0.9129);
  }
  to {
    transform: translate(82.92785vw, 100vh) scale(0.9129);
  }
}
.snow:nth-child(114) {
  opacity: 0.5238;
  transform: translate(11.9651vw, -10px) scale(0.0076);
  animation: fall-114 21s -1s linear infinite;
}
@keyframes fall-114 {
  63.788% {
    transform: translate(14.4124vw, 63.788vh) scale(0.0076);
  }
  to {
    transform: translate(13.18875vw, 100vh) scale(0.0076);
  }
}
.snow:nth-child(115) {
  opacity: 0.4217;
  transform: translate(12.2067vw, -10px) scale(0.6555);
  animation: fall-115 16s -3s linear infinite;
}
@keyframes fall-115 {
  41.897% {
    transform: translate(15.5384vw, 41.897vh) scale(0.6555);
  }
  to {
    transform: translate(13.87255vw, 100vh) scale(0.6555);
  }
}
.snow:nth-child(116) {
  opacity: 0.4755;
  transform: translate(64.0847vw, -10px) scale(0.1424);
  animation: fall-116 29s -2s linear infinite;
}
@keyframes fall-116 {
  59.06% {
    transform: translate(70.3563vw, 59.06vh) scale(0.1424);
  }
  to {
    transform: translate(67.2205vw, 100vh) scale(0.1424);
  }
}
.snow:nth-child(117) {
  opacity: 0.8017;
  transform: translate(17.172vw, -10px) scale(0.6196);
  animation: fall-117 20s -16s linear infinite;
}
@keyframes fall-117 {
  79.959% {
    transform: translate(22.1012vw, 79.959vh) scale(0.6196);
  }
  to {
    transform: translate(19.6366vw, 100vh) scale(0.6196);
  }
}
.snow:nth-child(118) {
  opacity: 0.6206;
  transform: translate(84.9114vw, -10px) scale(0.8938);
  animation: fall-118 30s -13s linear infinite;
}
@keyframes fall-118 {
  50.621% {
    transform: translate(80.871vw, 50.621vh) scale(0.8938);
  }
  to {
    transform: translate(82.8912vw, 100vh) scale(0.8938);
  }
}
.snow:nth-child(119) {
  opacity: 0.4689;
  transform: translate(63.4383vw, -10px) scale(0.0773);
  animation: fall-119 15s -10s linear infinite;
}
@keyframes fall-119 {
  42.223% {
    transform: translate(70.9832vw, 42.223vh) scale(0.0773);
  }
  to {
    transform: translate(67.21075vw, 100vh) scale(0.0773);
  }
}
.snow:nth-child(120) {
  opacity: 0.9236;
  transform: translate(76.8644vw, -10px) scale(0.4599);
  animation: fall-120 12s -20s linear infinite;
}
@keyframes fall-120 {
  76.369% {
    transform: translate(73.8903vw, 76.369vh) scale(0.4599);
  }
  to {
    transform: translate(75.37735vw, 100vh) scale(0.4599);
  }
}
.snow:nth-child(121) {
  opacity: 0.281;
  transform: translate(34.1331vw, -10px) scale(0.7385);
  animation: fall-121 27s -13s linear infinite;
}
@keyframes fall-121 {
  51.114% {
    transform: translate(42.4255vw, 51.114vh) scale(0.7385);
  }
  to {
    transform: translate(38.2793vw, 100vh) scale(0.7385);
  }
}
.snow:nth-child(122) {
  opacity: 0.683;
  transform: translate(94.8967vw, -10px) scale(0.7375);
  animation: fall-122 30s -17s linear infinite;
}
@keyframes fall-122 {
  37.201% {
    transform: translate(92.792vw, 37.201vh) scale(0.7375);
  }
  to {
    transform: translate(93.84435vw, 100vh) scale(0.7375);
  }
}
.snow:nth-child(123) {
  opacity: 0.6336;
  transform: translate(73.4188vw, -10px) scale(0.5162);
  animation: fall-123 29s -4s linear infinite;
}
@keyframes fall-123 {
  45.919% {
    transform: translate(69.4741vw, 45.919vh) scale(0.5162);
  }
  to {
    transform: translate(71.44645vw, 100vh) scale(0.5162);
  }
}
.snow:nth-child(124) {
  opacity: 0.4904;
  transform: translate(7.4738vw, -10px) scale(0.7847);
  animation: fall-124 13s -26s linear infinite;
}
@keyframes fall-124 {
  37.49% {
    transform: translate(10.6979vw, 37.49vh) scale(0.7847);
  }
  to {
    transform: translate(9.08585vw, 100vh) scale(0.7847);
  }
}
.snow:nth-child(125) {
  opacity: 0.2145;
  transform: translate(7.2741vw, -10px) scale(0.6822);
  animation: fall-125 29s -7s linear infinite;
}
@keyframes fall-125 {
  45.767% {
    transform: translate(-2.4653vw, 45.767vh) scale(0.6822);
  }
  to {
    transform: translate(2.4044vw, 100vh) scale(0.6822);
  }
}
.snow:nth-child(126) {
  opacity: 0.9364;
  transform: translate(65.6935vw, -10px) scale(0.0575);
  animation: fall-126 24s -26s linear infinite;
}
@keyframes fall-126 {
  66% {
    transform: translate(61.8628vw, 66vh) scale(0.0575);
  }
  to {
    transform: translate(63.77815vw, 100vh) scale(0.0575);
  }
}
.snow:nth-child(127) {
  opacity: 0.6862;
  transform: translate(93.7241vw, -10px) scale(0.9397);
  animation: fall-127 30s -26s linear infinite;
}
@keyframes fall-127 {
  63.327% {
    transform: translate(86.6661vw, 63.327vh) scale(0.9397);
  }
  to {
    transform: translate(90.1951vw, 100vh) scale(0.9397);
  }
}
.snow:nth-child(128) {
  opacity: 0.784;
  transform: translate(26.2863vw, -10px) scale(0.9871);
  animation: fall-128 29s -4s linear infinite;
}
@keyframes fall-128 {
  68.898% {
    transform: translate(19.1904vw, 68.898vh) scale(0.9871);
  }
  to {
    transform: translate(22.73835vw, 100vh) scale(0.9871);
  }
}
.snow:nth-child(129) {
  opacity: 0.2618;
  transform: translate(98.0291vw, -10px) scale(0.1043);
  animation: fall-129 13s -24s linear infinite;
}
@keyframes fall-129 {
  64.213% {
    transform: translate(103.8745vw, 64.213vh) scale(0.1043);
  }
  to {
    transform: translate(100.9518vw, 100vh) scale(0.1043);
  }
}
.snow:nth-child(130) {
  opacity: 0.9966;
  transform: translate(96.2579vw, -10px) scale(0.5506);
  animation: fall-130 23s -10s linear infinite;
}
@keyframes fall-130 {
  30.537% {
    transform: translate(88.1248vw, 30.537vh) scale(0.5506);
  }
  to {
    transform: translate(92.19135vw, 100vh) scale(0.5506);
  }
}
.snow:nth-child(131) {
  opacity: 0.6218;
  transform: translate(34.5825vw, -10px) scale(0.3466);
  animation: fall-131 17s -13s linear infinite;
}
@keyframes fall-131 {
  63.997% {
    transform: translate(35.8803vw, 63.997vh) scale(0.3466);
  }
  to {
    transform: translate(35.2314vw, 100vh) scale(0.3466);
  }
}
.snow:nth-child(132) {
  opacity: 0.0039;
  transform: translate(10.7152vw, -10px) scale(0.1988);
  animation: fall-132 23s -9s linear infinite;
}
@keyframes fall-132 {
  30.19% {
    transform: translate(1.1454vw, 30.19vh) scale(0.1988);
  }
  to {
    transform: translate(5.9303vw, 100vh) scale(0.1988);
  }
}
.snow:nth-child(133) {
  opacity: 0.6997;
  transform: translate(61.833vw, -10px) scale(0.9697);
  animation: fall-133 26s -6s linear infinite;
}
@keyframes fall-133 {
  69.116% {
    transform: translate(57.9257vw, 69.116vh) scale(0.9697);
  }
  to {
    transform: translate(59.87935vw, 100vh) scale(0.9697);
  }
}
.snow:nth-child(134) {
  opacity: 0.0759;
  transform: translate(38.3991vw, -10px) scale(0.1067);
  animation: fall-134 16s -9s linear infinite;
}
@keyframes fall-134 {
  76.683% {
    transform: translate(35.7409vw, 76.683vh) scale(0.1067);
  }
  to {
    transform: translate(37.07vw, 100vh) scale(0.1067);
  }
}
.snow:nth-child(135) {
  opacity: 0.7439;
  transform: translate(5.0019vw, -10px) scale(0.6306);
  animation: fall-135 11s -8s linear infinite;
}
@keyframes fall-135 {
  51.029% {
    transform: translate(11.8529vw, 51.029vh) scale(0.6306);
  }
  to {
    transform: translate(8.4274vw, 100vh) scale(0.6306);
  }
}
.snow:nth-child(136) {
  opacity: 0.115;
  transform: translate(17.3572vw, -10px) scale(0.1143);
  animation: fall-136 25s -24s linear infinite;
}
@keyframes fall-136 {
  52.019% {
    transform: translate(26.1518vw, 52.019vh) scale(0.1143);
  }
  to {
    transform: translate(21.7545vw, 100vh) scale(0.1143);
  }
}
.snow:nth-child(137) {
  opacity: 0.754;
  transform: translate(6.8237vw, -10px) scale(0.5933);
  animation: fall-137 15s -10s linear infinite;
}
@keyframes fall-137 {
  49.374% {
    transform: translate(13.6113vw, 49.374vh) scale(0.5933);
  }
  to {
    transform: translate(10.2175vw, 100vh) scale(0.5933);
  }
}
.snow:nth-child(138) {
  opacity: 0.53;
  transform: translate(58.825vw, -10px) scale(0.0721);
  animation: fall-138 25s -1s linear infinite;
}
@keyframes fall-138 {
  35.803% {
    transform: translate(59.5041vw, 35.803vh) scale(0.0721);
  }
  to {
    transform: translate(59.16455vw, 100vh) scale(0.0721);
  }
}
.snow:nth-child(139) {
  opacity: 0.2372;
  transform: translate(35.6357vw, -10px) scale(0.2787);
  animation: fall-139 28s -18s linear infinite;
}
@keyframes fall-139 {
  68.491% {
    transform: translate(35.9059vw, 68.491vh) scale(0.2787);
  }
  to {
    transform: translate(35.7708vw, 100vh) scale(0.2787);
  }
}
.snow:nth-child(140) {
  opacity: 0.5565;
  transform: translate(62.8894vw, -10px) scale(0.3773);
  animation: fall-140 19s -22s linear infinite;
}
@keyframes fall-140 {
  68.914% {
    transform: translate(60.4465vw, 68.914vh) scale(0.3773);
  }
  to {
    transform: translate(61.66795vw, 100vh) scale(0.3773);
  }
}
.snow:nth-child(141) {
  opacity: 0.9569;
  transform: translate(49.0035vw, -10px) scale(0.3122);
  animation: fall-141 10s -10s linear infinite;
}
@keyframes fall-141 {
  71.622% {
    transform: translate(54.2144vw, 71.622vh) scale(0.3122);
  }
  to {
    transform: translate(51.60895vw, 100vh) scale(0.3122);
  }
}
.snow:nth-child(142) {
  opacity: 0.8105;
  transform: translate(46.4477vw, -10px) scale(0.6225);
  animation: fall-142 11s -6s linear infinite;
}
@keyframes fall-142 {
  61.268% {
    transform: translate(41.1297vw, 61.268vh) scale(0.6225);
  }
  to {
    transform: translate(43.7887vw, 100vh) scale(0.6225);
  }
}
.snow:nth-child(143) {
  opacity: 0.8076;
  transform: translate(40.4354vw, -10px) scale(0.6173);
  animation: fall-143 29s -5s linear infinite;
}
@keyframes fall-143 {
  52.667% {
    transform: translate(32.7044vw, 52.667vh) scale(0.6173);
  }
  to {
    transform: translate(36.5699vw, 100vh) scale(0.6173);
  }
}
.snow:nth-child(144) {
  opacity: 0.2513;
  transform: translate(91.2858vw, -10px) scale(0.546);
  animation: fall-144 22s -21s linear infinite;
}
@keyframes fall-144 {
  77.6% {
    transform: translate(92.5686vw, 77.6vh) scale(0.546);
  }
  to {
    transform: translate(91.9272vw, 100vh) scale(0.546);
  }
}
.snow:nth-child(145) {
  opacity: 0.1351;
  transform: translate(50.6748vw, -10px) scale(0.4812);
  animation: fall-145 12s -19s linear infinite;
}
@keyframes fall-145 {
  42.362% {
    transform: translate(54.0544vw, 42.362vh) scale(0.4812);
  }
  to {
    transform: translate(52.3646vw, 100vh) scale(0.4812);
  }
}
.snow:nth-child(146) {
  opacity: 0.8294;
  transform: translate(8.6344vw, -10px) scale(0.2254);
  animation: fall-146 29s -26s linear infinite;
}
@keyframes fall-146 {
  64.361% {
    transform: translate(18.489vw, 64.361vh) scale(0.2254);
  }
  to {
    transform: translate(13.5617vw, 100vh) scale(0.2254);
  }
}
.snow:nth-child(147) {
  opacity: 0.0604;
  transform: translate(96.3495vw, -10px) scale(0.8713);
  animation: fall-147 16s -14s linear infinite;
}
@keyframes fall-147 {
  69.004% {
    transform: translate(95.2183vw, 69.004vh) scale(0.8713);
  }
  to {
    transform: translate(95.7839vw, 100vh) scale(0.8713);
  }
}
.snow:nth-child(148) {
  opacity: 0.4193;
  transform: translate(14.1512vw, -10px) scale(0.3165);
  animation: fall-148 17s -18s linear infinite;
}
@keyframes fall-148 {
  50.123% {
    transform: translate(4.187vw, 50.123vh) scale(0.3165);
  }
  to {
    transform: translate(9.1691vw, 100vh) scale(0.3165);
  }
}
.snow:nth-child(149) {
  opacity: 0.6655;
  transform: translate(85.612vw, -10px) scale(0.3206);
  animation: fall-149 24s -29s linear infinite;
}
@keyframes fall-149 {
  73.94% {
    transform: translate(77.5048vw, 73.94vh) scale(0.3206);
  }
  to {
    transform: translate(81.5584vw, 100vh) scale(0.3206);
  }
}
.snow:nth-child(150) {
  opacity: 0.6036;
  transform: translate(22.469vw, -10px) scale(0.0161);
  animation: fall-150 20s -29s linear infinite;
}
@keyframes fall-150 {
  79.541% {
    transform: translate(27.8685vw, 79.541vh) scale(0.0161);
  }
  to {
    transform: translate(25.16875vw, 100vh) scale(0.0161);
  }
}
.snow:nth-child(151) {
  opacity: 0.1917;
  transform: translate(35.1714vw, -10px) scale(0.7042);
  animation: fall-151 11s -14s linear infinite;
}
@keyframes fall-151 {
  70.12% {
    transform: translate(42.1672vw, 70.12vh) scale(0.7042);
  }
  to {
    transform: translate(38.6693vw, 100vh) scale(0.7042);
  }
}
.snow:nth-child(152) {
  opacity: 0.1088;
  transform: translate(89.4045vw, -10px) scale(0.8232);
  animation: fall-152 15s -9s linear infinite;
}
@keyframes fall-152 {
  31.808% {
    transform: translate(96.8915vw, 31.808vh) scale(0.8232);
  }
  to {
    transform: translate(93.148vw, 100vh) scale(0.8232);
  }
}
.snow:nth-child(153) {
  opacity: 0.8925;
  transform: translate(39.6312vw, -10px) scale(0.5857);
  animation: fall-153 24s -10s linear infinite;
}
@keyframes fall-153 {
  57.452% {
    transform: translate(43.8947vw, 57.452vh) scale(0.5857);
  }
  to {
    transform: translate(41.76295vw, 100vh) scale(0.5857);
  }
}
.snow:nth-child(154) {
  opacity: 0.368;
  transform: translate(48.7703vw, -10px) scale(0.7291);
  animation: fall-154 24s -10s linear infinite;
}
@keyframes fall-154 {
  46.809% {
    transform: translate(54.9551vw, 46.809vh) scale(0.7291);
  }
  to {
    transform: translate(51.8627vw, 100vh) scale(0.7291);
  }
}
.snow:nth-child(155) {
  opacity: 0.5327;
  transform: translate(8.8853vw, -10px) scale(0.529);
  animation: fall-155 12s -14s linear infinite;
}
@keyframes fall-155 {
  41.224% {
    transform: translate(5.456vw, 41.224vh) scale(0.529);
  }
  to {
    transform: translate(7.17065vw, 100vh) scale(0.529);
  }
}
.snow:nth-child(156) {
  opacity: 0.977;
  transform: translate(71.0687vw, -10px) scale(0.7596);
  animation: fall-156 15s -18s linear infinite;
}
@keyframes fall-156 {
  38.371% {
    transform: translate(70.1152vw, 38.371vh) scale(0.7596);
  }
  to {
    transform: translate(70.59195vw, 100vh) scale(0.7596);
  }
}
.snow:nth-child(157) {
  opacity: 0.2846;
  transform: translate(62.5666vw, -10px) scale(0.5544);
  animation: fall-157 29s -24s linear infinite;
}
@keyframes fall-157 {
  66.336% {
    transform: translate(56.8816vw, 66.336vh) scale(0.5544);
  }
  to {
    transform: translate(59.7241vw, 100vh) scale(0.5544);
  }
}
.snow:nth-child(158) {
  opacity: 0.3902;
  transform: translate(36.3046vw, -10px) scale(0.3441);
  animation: fall-158 18s -5s linear infinite;
}
@keyframes fall-158 {
  63.068% {
    transform: translate(41.0581vw, 63.068vh) scale(0.3441);
  }
  to {
    transform: translate(38.68135vw, 100vh) scale(0.3441);
  }
}
.snow:nth-child(159) {
  opacity: 0.651;
  transform: translate(6.5471vw, -10px) scale(0.2598);
  animation: fall-159 18s -12s linear infinite;
}
@keyframes fall-159 {
  41.252% {
    transform: translate(0.4885vw, 41.252vh) scale(0.2598);
  }
  to {
    transform: translate(3.5178vw, 100vh) scale(0.2598);
  }
}
.snow:nth-child(160) {
  opacity: 0.5847;
  transform: translate(83.9843vw, -10px) scale(0.2506);
  animation: fall-160 18s -21s linear infinite;
}
@keyframes fall-160 {
  58.885% {
    transform: translate(76.6025vw, 58.885vh) scale(0.2506);
  }
  to {
    transform: translate(80.2934vw, 100vh) scale(0.2506);
  }
}
.snow:nth-child(161) {
  opacity: 0.3333;
  transform: translate(24.7187vw, -10px) scale(0.2005);
  animation: fall-161 21s -6s linear infinite;
}
@keyframes fall-161 {
  74.819% {
    transform: translate(31.3376vw, 74.819vh) scale(0.2005);
  }
  to {
    transform: translate(28.02815vw, 100vh) scale(0.2005);
  }
}
.snow:nth-child(162) {
  opacity: 0.9158;
  transform: translate(78.6029vw, -10px) scale(0.1973);
  animation: fall-162 21s -15s linear infinite;
}
@keyframes fall-162 {
  65.134% {
    transform: translate(72.9424vw, 65.134vh) scale(0.1973);
  }
  to {
    transform: translate(75.77265vw, 100vh) scale(0.1973);
  }
}
.snow:nth-child(163) {
  opacity: 0.8632;
  transform: translate(26.8413vw, -10px) scale(0.7638);
  animation: fall-163 29s -13s linear infinite;
}
@keyframes fall-163 {
  48.824% {
    transform: translate(18.947vw, 48.824vh) scale(0.7638);
  }
  to {
    transform: translate(22.89415vw, 100vh) scale(0.7638);
  }
}
.snow:nth-child(164) {
  opacity: 0.7687;
  transform: translate(14.7634vw, -10px) scale(0.8652);
  animation: fall-164 12s -17s linear infinite;
}
@keyframes fall-164 {
  61.835% {
    transform: translate(24.1541vw, 61.835vh) scale(0.8652);
  }
  to {
    transform: translate(19.45875vw, 100vh) scale(0.8652);
  }
}
.snow:nth-child(165) {
  opacity: 0.8874;
  transform: translate(22.8053vw, -10px) scale(0.2168);
  animation: fall-165 18s -8s linear infinite;
}
@keyframes fall-165 {
  70.272% {
    transform: translate(28.5244vw, 70.272vh) scale(0.2168);
  }
  to {
    transform: translate(25.66485vw, 100vh) scale(0.2168);
  }
}
.snow:nth-child(166) {
  opacity: 0.6839;
  transform: translate(64.6407vw, -10px) scale(0.9454);
  animation: fall-166 17s -24s linear infinite;
}
@keyframes fall-166 {
  71.902% {
    transform: translate(55.2708vw, 71.902vh) scale(0.9454);
  }
  to {
    transform: translate(59.95575vw, 100vh) scale(0.9454);
  }
}
.snow:nth-child(167) {
  opacity: 0.7225;
  transform: translate(14.0829vw, -10px) scale(0.931);
  animation: fall-167 13s -10s linear infinite;
}
@keyframes fall-167 {
  52.553% {
    transform: translate(8.8046vw, 52.553vh) scale(0.931);
  }
  to {
    transform: translate(11.44375vw, 100vh) scale(0.931);
  }
}
.snow:nth-child(168) {
  opacity: 0.517;
  transform: translate(79.7132vw, -10px) scale(0.8366);
  animation: fall-168 16s -5s linear infinite;
}
@keyframes fall-168 {
  49.534% {
    transform: translate(72.8691vw, 49.534vh) scale(0.8366);
  }
  to {
    transform: translate(76.29115vw, 100vh) scale(0.8366);
  }
}
.snow:nth-child(169) {
  opacity: 0.5019;
  transform: translate(7.8158vw, -10px) scale(0.4382);
  animation: fall-169 15s -5s linear infinite;
}
@keyframes fall-169 {
  41.837% {
    transform: translate(12.3486vw, 41.837vh) scale(0.4382);
  }
  to {
    transform: translate(10.0822vw, 100vh) scale(0.4382);
  }
}
.snow:nth-child(170) {
  opacity: 0.1988;
  transform: translate(73.9067vw, -10px) scale(0.1682);
  animation: fall-170 29s -11s linear infinite;
}
@keyframes fall-170 {
  55.968% {
    transform: translate(70.4008vw, 55.968vh) scale(0.1682);
  }
  to {
    transform: translate(72.15375vw, 100vh) scale(0.1682);
  }
}
.snow:nth-child(171) {
  opacity: 0.122;
  transform: translate(18.7375vw, -10px) scale(1);
  animation: fall-171 19s -5s linear infinite;
}
@keyframes fall-171 {
  64.66% {
    transform: translate(9.8129vw, 64.66vh) scale(1);
  }
  to {
    transform: translate(14.2752vw, 100vh) scale(1);
  }
}
.snow:nth-child(172) {
  opacity: 0.9168;
  transform: translate(52.374vw, -10px) scale(0.2724);
  animation: fall-172 20s -26s linear infinite;
}
@keyframes fall-172 {
  58.266% {
    transform: translate(61.7399vw, 58.266vh) scale(0.2724);
  }
  to {
    transform: translate(57.05695vw, 100vh) scale(0.2724);
  }
}
.snow:nth-child(173) {
  opacity: 0.4593;
  transform: translate(29.4684vw, -10px) scale(0.9577);
  animation: fall-173 30s -8s linear infinite;
}
@keyframes fall-173 {
  65.199% {
    transform: translate(25.2809vw, 65.199vh) scale(0.9577);
  }
  to {
    transform: translate(27.37465vw, 100vh) scale(0.9577);
  }
}
.snow:nth-child(174) {
  opacity: 0.7177;
  transform: translate(46.6406vw, -10px) scale(0.4737);
  animation: fall-174 28s -2s linear infinite;
}
@keyframes fall-174 {
  43.98% {
    transform: translate(41.607vw, 43.98vh) scale(0.4737);
  }
  to {
    transform: translate(44.1238vw, 100vh) scale(0.4737);
  }
}
.snow:nth-child(175) {
  opacity: 0.3167;
  transform: translate(30.2134vw, -10px) scale(0.6126);
  animation: fall-175 30s -5s linear infinite;
}
@keyframes fall-175 {
  64.364% {
    transform: translate(30.9878vw, 64.364vh) scale(0.6126);
  }
  to {
    transform: translate(30.6006vw, 100vh) scale(0.6126);
  }
}
.snow:nth-child(176) {
  opacity: 0.3413;
  transform: translate(27.4098vw, -10px) scale(0.3021);
  animation: fall-176 10s -21s linear infinite;
}
@keyframes fall-176 {
  53.784% {
    transform: translate(24.1496vw, 53.784vh) scale(0.3021);
  }
  to {
    transform: translate(25.7797vw, 100vh) scale(0.3021);
  }
}
.snow:nth-child(177) {
  opacity: 0.9989;
  transform: translate(9vw, -10px) scale(0.1506);
  animation: fall-177 12s -29s linear infinite;
}
@keyframes fall-177 {
  37.963% {
    transform: translate(11.3155vw, 37.963vh) scale(0.1506);
  }
  to {
    transform: translate(10.15775vw, 100vh) scale(0.1506);
  }
}
.snow:nth-child(178) {
  opacity: 0.6842;
  transform: translate(62.2667vw, -10px) scale(0.3615);
  animation: fall-178 19s -19s linear infinite;
}
@keyframes fall-178 {
  45.469% {
    transform: translate(58.5039vw, 45.469vh) scale(0.3615);
  }
  to {
    transform: translate(60.3853vw, 100vh) scale(0.3615);
  }
}
.snow:nth-child(179) {
  opacity: 0.1727;
  transform: translate(67.5188vw, -10px) scale(0.5986);
  animation: fall-179 14s -29s linear infinite;
}
@keyframes fall-179 {
  42.099% {
    transform: translate(69.0372vw, 42.099vh) scale(0.5986);
  }
  to {
    transform: translate(68.278vw, 100vh) scale(0.5986);
  }
}
.snow:nth-child(180) {
  opacity: 0.516;
  transform: translate(86.8371vw, -10px) scale(0.2343);
  animation: fall-180 25s -27s linear infinite;
}
@keyframes fall-180 {
  65.912% {
    transform: translate(78.2258vw, 65.912vh) scale(0.2343);
  }
  to {
    transform: translate(82.53145vw, 100vh) scale(0.2343);
  }
}
.snow:nth-child(181) {
  opacity: 0.7092;
  transform: translate(82.9922vw, -10px) scale(0.1629);
  animation: fall-181 24s -16s linear infinite;
}
@keyframes fall-181 {
  77.942% {
    transform: translate(82.5857vw, 77.942vh) scale(0.1629);
  }
  to {
    transform: translate(82.78895vw, 100vh) scale(0.1629);
  }
}
.snow:nth-child(182) {
  opacity: 0.422;
  transform: translate(89.5371vw, -10px) scale(0.2606);
  animation: fall-182 20s -21s linear infinite;
}
@keyframes fall-182 {
  56.304% {
    transform: translate(92.9103vw, 56.304vh) scale(0.2606);
  }
  to {
    transform: translate(91.2237vw, 100vh) scale(0.2606);
  }
}
.snow:nth-child(183) {
  opacity: 0.7869;
  transform: translate(82.6487vw, -10px) scale(0.2263);
  animation: fall-183 17s -26s linear infinite;
}
@keyframes fall-183 {
  39.229% {
    transform: translate(73.2636vw, 39.229vh) scale(0.2263);
  }
  to {
    transform: translate(77.95615vw, 100vh) scale(0.2263);
  }
}
.snow:nth-child(184) {
  opacity: 0.9771;
  transform: translate(82.7476vw, -10px) scale(0.826);
  animation: fall-184 12s -1s linear infinite;
}
@keyframes fall-184 {
  50.912% {
    transform: translate(83.5072vw, 50.912vh) scale(0.826);
  }
  to {
    transform: translate(83.1274vw, 100vh) scale(0.826);
  }
}
.snow:nth-child(185) {
  opacity: 0.6536;
  transform: translate(82.1402vw, -10px) scale(0.0857);
  animation: fall-185 10s -27s linear infinite;
}
@keyframes fall-185 {
  31.664% {
    transform: translate(91.8525vw, 31.664vh) scale(0.0857);
  }
  to {
    transform: translate(86.99635vw, 100vh) scale(0.0857);
  }
}
.snow:nth-child(186) {
  opacity: 0.4608;
  transform: translate(90.4685vw, -10px) scale(0.8289);
  animation: fall-186 29s -27s linear infinite;
}
@keyframes fall-186 {
  48.904% {
    transform: translate(85.2096vw, 48.904vh) scale(0.8289);
  }
  to {
    transform: translate(87.83905vw, 100vh) scale(0.8289);
  }
}
.snow:nth-child(187) {
  opacity: 0.6028;
  transform: translate(47.6802vw, -10px) scale(0.7848);
  animation: fall-187 22s -6s linear infinite;
}
@keyframes fall-187 {
  41.174% {
    transform: translate(46.9297vw, 41.174vh) scale(0.7848);
  }
  to {
    transform: translate(47.30495vw, 100vh) scale(0.7848);
  }
}
.snow:nth-child(188) {
  opacity: 0.9967;
  transform: translate(59.7426vw, -10px) scale(0.9427);
  animation: fall-188 10s -13s linear infinite;
}
@keyframes fall-188 {
  32.396% {
    transform: translate(59.8458vw, 32.396vh) scale(0.9427);
  }
  to {
    transform: translate(59.7942vw, 100vh) scale(0.9427);
  }
}
.snow:nth-child(189) {
  opacity: 0.6845;
  transform: translate(91.7vw, -10px) scale(0.5455);
  animation: fall-189 27s -13s linear infinite;
}
@keyframes fall-189 {
  59.74% {
    transform: translate(88.7595vw, 59.74vh) scale(0.5455);
  }
  to {
    transform: translate(90.22975vw, 100vh) scale(0.5455);
  }
}
.snow:nth-child(190) {
  opacity: 0.3405;
  transform: translate(96.2434vw, -10px) scale(0.7195);
  animation: fall-190 22s -3s linear infinite;
}
@keyframes fall-190 {
  47.428% {
    transform: translate(90.92vw, 47.428vh) scale(0.7195);
  }
  to {
    transform: translate(93.5817vw, 100vh) scale(0.7195);
  }
}
.snow:nth-child(191) {
  opacity: 0.047;
  transform: translate(27.6949vw, -10px) scale(0.8844);
  animation: fall-191 13s -21s linear infinite;
}
@keyframes fall-191 {
  48.412% {
    transform: translate(33.663vw, 48.412vh) scale(0.8844);
  }
  to {
    transform: translate(30.67895vw, 100vh) scale(0.8844);
  }
}
.snow:nth-child(192) {
  opacity: 0.3404;
  transform: translate(60.31vw, -10px) scale(0.6593);
  animation: fall-192 21s -4s linear infinite;
}
@keyframes fall-192 {
  53.444% {
    transform: translate(60.4807vw, 53.444vh) scale(0.6593);
  }
  to {
    transform: translate(60.39535vw, 100vh) scale(0.6593);
  }
}
.snow:nth-child(193) {
  opacity: 0.7421;
  transform: translate(84.4636vw, -10px) scale(0.4084);
  animation: fall-193 21s -27s linear infinite;
}
@keyframes fall-193 {
  75.353% {
    transform: translate(88.6757vw, 75.353vh) scale(0.4084);
  }
  to {
    transform: translate(86.56965vw, 100vh) scale(0.4084);
  }
}
.snow:nth-child(194) {
  opacity: 0.1146;
  transform: translate(95.7571vw, -10px) scale(0.3329);
  animation: fall-194 23s -19s linear infinite;
}
@keyframes fall-194 {
  45.064% {
    transform: translate(101.4622vw, 45.064vh) scale(0.3329);
  }
  to {
    transform: translate(98.60965vw, 100vh) scale(0.3329);
  }
}
.snow:nth-child(195) {
  opacity: 0.3466;
  transform: translate(78.7092vw, -10px) scale(0.9762);
  animation: fall-195 25s -12s linear infinite;
}
@keyframes fall-195 {
  75.443% {
    transform: translate(75.7066vw, 75.443vh) scale(0.9762);
  }
  to {
    transform: translate(77.2079vw, 100vh) scale(0.9762);
  }
}
.snow:nth-child(196) {
  opacity: 0.4673;
  transform: translate(79.3162vw, -10px) scale(0.357);
  animation: fall-196 24s -6s linear infinite;
}
@keyframes fall-196 {
  32.412% {
    transform: translate(72.5129vw, 32.412vh) scale(0.357);
  }
  to {
    transform: translate(75.91455vw, 100vh) scale(0.357);
  }
}
.snow:nth-child(197) {
  opacity: 0.5522;
  transform: translate(7.0482vw, -10px) scale(0.3428);
  animation: fall-197 13s -13s linear infinite;
}
@keyframes fall-197 {
  35.083% {
    transform: translate(15.5949vw, 35.083vh) scale(0.3428);
  }
  to {
    transform: translate(11.32155vw, 100vh) scale(0.3428);
  }
}
.snow:nth-child(198) {
  opacity: 0.0381;
  transform: translate(47.7639vw, -10px) scale(0.3847);
  animation: fall-198 29s -15s linear infinite;
}
@keyframes fall-198 {
  63.647% {
    transform: translate(46.2495vw, 63.647vh) scale(0.3847);
  }
  to {
    transform: translate(47.0067vw, 100vh) scale(0.3847);
  }
}
.snow:nth-child(199) {
  opacity: 0.661;
  transform: translate(9.3507vw, -10px) scale(0.4607);
  animation: fall-199 24s -17s linear infinite;
}
@keyframes fall-199 {
  31.525% {
    transform: translate(0.7461vw, 31.525vh) scale(0.4607);
  }
  to {
    transform: translate(5.0484vw, 100vh) scale(0.4607);
  }
}
.snow:nth-child(200) {
  opacity: 0.0479;
  transform: translate(45.2802vw, -10px) scale(0.2038);
  animation: fall-200 26s -27s linear infinite;
}
@keyframes fall-200 {
  47.69% {
    transform: translate(44.1015vw, 47.69vh) scale(0.2038);
  }
  to {
    transform: translate(44.69085vw, 100vh) scale(0.2038);
  }
}

@media screen and (max-width: 499px) {
  ::-webkit-scrollbar {
    width: 10px;
   }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(51, 203, 241);
  }
  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }
  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: transform .2s;
  }


  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 50px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 20px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  #fontSize2:hover {
    color: rgb(80, 229, 255);
    text-align: left;
    text-shadow: 0 0 10px #65c2f8;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #00fefe 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 20%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(192,46,123,0.5010175945378151) 0%, rgba(6,0,87,0) 41%, rgba(25,18,40,0) 100%), url('./assets/bg3.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .submit:hover {
    transform: scale(1.1);
  }
  
  .emailMain {
    background: rgb(244, 244, 244);
    background: linear-gradient(180deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 1) 35%, rgba(255, 255, 255, 1) 100%);
    padding-top: 50px;
  
  }
  
  .osPic3:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .socialMain {
    color: #0b1016;
    font-size: 16.8px;
    justify-content: flex-start;
    padding: 25px;
    margin-top: -50px;
  }
  
  .socialMainMob {
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
    display: none;
  }
  
  .socialMain2 {
    justify-content: center;
  
  }
  
  
  .osPic3 {
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: transform .2s;
  }
  
  .email {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }
  
  .submit {
    transition: transform .2s;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 25px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
  
  }
  
  .emailCon {
    font-size: 25px;
    text-align: center;
    color: white;
  
  }
  
  .handshake {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 10px;
  }
  
  
  input {
    padding: 10px;
    border: 2px solid black;
    border-radius: 7px;
  }
  
  .connect2Moile {
    display: none;
  }
  
  .iconsMobile {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -5%;
    margin-left: -10%;
    margin-top: -40%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 40px;
    height: 40px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 83%;
    margin-top: -15%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }
  
  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
  
  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }
  
  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }
  
  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }
  
  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }
  
  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }
  
  
  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
  
  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }
  
  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }
  
  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }
  
  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }
  
  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }
  
  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }
  
  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }
  
  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }
  
  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }
  
  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }
  
  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  
  
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(25,18,40);
    background: linear-gradient(180deg, rgba(25,18,40,1) 0%, rgba(40,97,149,1) 41%, rgba(0,17,33,1) 100%);
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
  }
   
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 50px;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12, 17, 19, 0.33575148809523814) 0%, rgba(12, 17, 19, 0.36656381302521013) 35%, rgba(12, 17, 19, 0.7391128326330532) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer img {
    width: 450px;
    height: 116px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }
  
  .sharks {
    width: 100%;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    background-color: #06223583;
    box-shadow: 0 0 20px #23bfe6;
    border: 2px #9fecff solid;
    width: 90%;
    margin-top: 40px;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 70px;
    color: white;
    font-family: 'Archivo Black', sans-serif;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    -webkit-text-stroke: 1px #a4dfff;
  
  }
  
  
  
  .ob {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    color: #1a6e2e;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 90px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;
  }
  
  .mintName {
    color: rgb(241, 1, 1);
    font-size: 70px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyCon3 {
    font-size: 22px;
    margin-top: 20px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 15px #27a0f7;
  }
  
  .storyCon2-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 35px;
    margin-top: 20px;
    text-align: center;
    color: black;
  }
  
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 180px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }
  
  .logoF {
    color: white;
    text-align: center;
    font-size: 30px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
  }
  
  .socialMain2 img {
    width: 50%;
    height: 50%;
  }
  
  .intro img {
    width: 40%;
    z-index: 1000000000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 6%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    border: 5px solid #c35bd1;
    margin-top: 50px;
    box-shadow: -10px 10px #73347b;
  }
  
.placeAndWL {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
   /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  background: rgba(82, 82, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 30px;
  width: 90%;
  margin-bottom: 10%;

}

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    text-shadow: -2.5px 0 red;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;    text-align: center;
    color: white;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
    text-shadow: -2px 0 #ff00c1;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
  }
  
  .success2 {
    font-size: 25px;
    text-align: center;
    color: white;
    margin-top: 1%;
  
  }
  
  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Barlow Condensed', sans-serif;
    color: #00E6F6;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  }
  
  .connect div:hover {
    color: white;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: 2px white solid;
    box-shadow: 0 0 10px #ffffff;
    cursor: pointer;
    background-color: #6cfc1900;
    color: rgba(134, 212, 243, 0.952);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: white;
  
    font-size: 25px;
    text-shadow: -3px 0 #1ee03e;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #102738;
    box-shadow: 0px 5px #1271b0;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    display: none;

  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .button-87 {
    background-color: #00000000;
    border: #ffffff 2px solid;
    font-size: 18px;
    color: #00E6F6;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    padding: 10px;
    width: 170px;
    margin-left: 10px;
    transition: 0.3s ease;
    box-shadow: 0 0 10px #ffffff;
  }
  
  .button-87:hover{
    border: #00E6F6 2px solid;
    color: #ffffff;
    box-shadow: 0 0 10px #23bfe6;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    margin-top: -37px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    cursor: pointer;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #00E6F6;
  }
  
  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 20px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    border: 2px white solid;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 80%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background:  linear-gradient(180deg, rgba(0,17,33,1) 0%, rgba(6,0,87,0.41698398109243695) 41%, rgba(25,18,40,1) 100%), url('./assets/faq.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
     height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  
  }
  
  
  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    
  }
  
  .vidCont {
    object-fit: cover;
    width: 100%;
  }
  
  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
    */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
  
  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
    z-index: 10000;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
     font-size: 25px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: #90e9f8;
    font-size: 19px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 90%;
    box-shadow: 0 0 10px #23bfe6;
    transition: all 0.3s;
    transform: scale(1);
  }
  
  .man:hover{
    transform: scale(1.1);
  }
  
  .man2:hover{
    transform: scale(1.1);
  }
  
  .man2 {
    width: 100%;  
    transition: all 0.3s;
    transform: scale(1);
  }
  
  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 80px;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
  
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }
  
  .dripCover {
    width: 100%;
  }
  
  .videoBg {
    position: fixed;
    top: 55%;
    left: 55%;
    min-width: 100%;
    min-height: 100%;
    width: 200%;
    height: 200%;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
  }
  
  .mintMain {
    display: flex;
    justify-content: space-between;
    background: rgb(12, 10, 19);
    background: linear-gradient(180deg, rgba(12, 10, 19, 1) 0%, rgba(0, 38, 73, 1) 41%, rgba(0, 17, 33, 1) 100%);
  }
  
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .glitch {
    color: rgb(255, 255, 255);
    font-size: 55px;
    text-transform: upercase;
    position: relative;
    display: inline-block;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  
  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  /* CSS */
  .button-49,
  .button-49:after {
    width: 160px;
    height: 76px;
    line-height: 70px;
    font-size: 22px;
    background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-top: 30px;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
  
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
  
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
  
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
  
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
  
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
  
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
  
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
  
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
  
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
  
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 300px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  
  /* CSS */
  .button-49,
  .button-49:after {
    width: fit-content;
 
    height: 76px;
    line-height: 78px;
    font-size: 22px;
    background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    user-select: none;
    margin-top: 30px;
  
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
  
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
  
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
  
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
  
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
  
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
  
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
  
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
  
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
  
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
  
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 200px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background: rgb(18,116,181);
    background: linear-gradient(180deg, rgba(18,116,181,1) 0%, rgba(61,68,132,1) 41%, rgba(213,67,139,1) 100%);  padding-left: 100%;
    background-size: contain;
    z-index: 100000000;
  
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 60s;
    z-index: 100000000;
  
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 23px;
    color: #4cb2f7;
    text-shadow: #47474763 3px 5px 2px;
    letter-spacing: 2px;
    z-index: 100000000;
  
  } 
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }
  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: transform .2s;
  }


  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 50px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 20px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  #fontSize2:hover {
    color: rgb(80, 229, 255);
    text-align: left;
    text-shadow: 0 0 10px #65c2f8;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #00fefe 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 20%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(192,46,123,0.5010175945378151) 0%, rgba(6,0,87,0) 41%, rgba(25,18,40,0) 100%), url('./assets/bg3.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .submit:hover {
    transform: scale(1.1);
  }
  
  .emailMain {
    background: rgb(244, 244, 244);
    background: linear-gradient(180deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 1) 35%, rgba(255, 255, 255, 1) 100%);
    padding-top: 50px;
  
  }
  
  .osPic3:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .socialMain {
    color: #0b1016;
    font-size: 16.8px;
    justify-content: flex-start;
    padding: 25px;
    margin-top: -50px;
  }
  
  .socialMainMob {
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
    display: none;
  }
  
  .socialMain2 {
    justify-content: center;
  
  }
  
  
  .osPic3 {
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: transform .2s;
  }
  
  .email {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }
  
  .submit {
    transition: transform .2s;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 25px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
  
  }
  
  .emailCon {
    font-size: 25px;
    text-align: center;
    color: white;
  
  }
  
  .handshake {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 10px;
  }
  
  
  input {
    padding: 10px;
    border: 2px solid black;
    border-radius: 7px;
  }
  
  .connect2Moile {
    display: none;
  }
  
  .iconsMobile {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -5%;
    margin-left: -10%;
    margin-top: -40%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 40px;
    height: 40px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 87%;
    margin-top: -8%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }
  
  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
  
  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }
  
  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }
  
  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }
  
  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }
  
  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }
  
  
  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
  
  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }
  
  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }
  
  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }
  
  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }
  
  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }
  
  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }
  
  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }
  
  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }
  
  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }
  
  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }
  
  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  
  
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(25,18,40);
    background: linear-gradient(180deg, rgba(25,18,40,1) 0%, rgba(40,97,149,1) 41%, rgba(0,17,33,1) 100%);
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
  }
   
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 50px;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12, 17, 19, 0.33575148809523814) 0%, rgba(12, 17, 19, 0.36656381302521013) 35%, rgba(12, 17, 19, 0.7391128326330532) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer img {
    width: 450px;
    height: 116px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }
  
  .sharks {
    width: 100%;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    background-color: #06223583;
    box-shadow: 0 0 20px #23bfe6;
    border: 2px #9fecff solid;
    width: 90%;
    margin-top: 25px;

  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 70px;
    color: white;
    font-family: 'Archivo Black', sans-serif;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    -webkit-text-stroke: 1px #a4dfff;
  
  }
  
  
  
  .ob {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    color: #1a6e2e;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 90px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;
  }
  
  .mintName {
    color: rgb(241, 1, 1);
    font-size: 70px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyCon3 {
    font-size: 22px;
    margin-top: 20px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 15px #27a0f7;
  }
  
  .storyCon2-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 35px;
    margin-top: 20px;
    text-align: center;
    color: black;
  }
  
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 180px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }
  
  .logoF {
    color: white;
    text-align: center;
    font-size: 30px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
  }
  
  .socialMain2 img {
    width: 40%;
    height: 40%;
  }
  
  .intro img {
    width: 40%;
    z-index: 1000000000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    border: 5px solid #c35bd1;
    margin-top: 50px;
    box-shadow: -10px 10px #73347b;


  }
  
.placeAndWL {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
   /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  background: rgba(82, 82, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 30px;
  width: 90%;
  margin-bottom: 10%;
}

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    text-shadow: -2.5px 0 red;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;    text-align: center;
    color: white;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 25px;
    text-align: center;
    color: white;
    text-shadow: -2px 0 #ff00c1;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
  }
  
  .success2 {
    font-size: 25px;
    text-align: center;
    color: white;
    margin-top: 1%;
  
  }
  
  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Barlow Condensed', sans-serif;
    color: #00E6F6;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  }
  
  .connect div:hover {
    color: white;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: 2px white solid;
    box-shadow: 0 0 10px #ffffff;
    cursor: pointer;
    background-color: #6cfc1900;
    color: rgba(134, 212, 243, 0.952);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: white;
  
    font-size: 25px;
    text-shadow: -3px 0 #1ee03e;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #102738;
    box-shadow: 0px 5px #1271b0;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    display: none;

  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .button-87 {
    background-color: #00000000;
    border: #ffffff 2px solid;
    font-size: 20px;
    color: #00E6F6;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    padding: 10px;
    width: 200px;
    margin-left: 10px;
    transition: 0.3s ease;
    box-shadow: 0 0 10px #ffffff;
  }
  
  .button-87:hover{
    border: #00E6F6 2px solid;
    color: #ffffff;
    box-shadow: 0 0 10px #23bfe6;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    margin-top: -37px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    cursor: pointer;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #00E6F6;
  }
  
  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 20px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    border: 2px white solid;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 80%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background:  linear-gradient(180deg, rgba(0,17,33,1) 0%, rgba(6,0,87,0.41698398109243695) 41%, rgba(25,18,40,1) 100%), url('./assets/faq.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
     height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  
  }
  
  
  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .vidCont {
    object-fit: cover;
    width: 100%;
  }
  
  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
    */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
  
  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
    z-index: 10000;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
     font-size: 27px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: #90e9f8;
    font-size: 20px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 90%;
    box-shadow: 0 0 10px #23bfe6;
    transition: all 0.3s;
    transform: scale(1);
  }
  
  .man:hover{
    transform: scale(1.1);
  }
  
  .man2:hover{
    transform: scale(1.1);
  }
  
  .man2 {
    width: 100%;  
    transition: all 0.3s;
    transform: scale(1);
  }
  
  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 80px;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
  
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }
  
  .dripCover {
    width: 100%;
  }
  
  .videoBg {
    position: fixed;
    top: 55%;
    left: 55%;
    min-width: 100%;
    min-height: 100%;
    width: 200%;
    height: 200%;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
  }
  
  .mintMain {
    display: flex;
    justify-content: space-between;
    background: rgb(12, 10, 19);
    background: linear-gradient(180deg, rgba(12, 10, 19, 1) 0%, rgba(0, 38, 73, 1) 41%, rgba(0, 17, 33, 1) 100%);
  }
  
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .glitch {
    color: rgb(255, 255, 255);
    font-size: 70px;
    text-transform: upercase;
    position: relative;
    display: inline-block;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  
  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  /* CSS */
  .button-49,
  .button-49:after {
    width: 200px;
    height: 76px;
    line-height: 70px;
    font-size: 25px;
    background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-top: 30px;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
  
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
  
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
  
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
  
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
  
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
  
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
  
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
  
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
  
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
  
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 400px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  
  /* CSS */
  .button-49,
  .button-49:after {
    width: 320px;
    height: 76px;
    line-height: 78px;
    font-size: 25px;
    background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    user-select: none;
    margin-top: 30px;
  
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
  
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
  
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
  
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
  
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
  
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
  
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
  
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
  
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
  
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
  
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 300px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    background: rgb(18,116,181);
    background: linear-gradient(180deg, rgba(18,116,181,1) 0%, rgba(61,68,132,1) 41%, rgba(213,67,139,1) 100%);  padding-left: 100%;
    background-size: contain;
    z-index: 100000000;
  
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 60s;
    z-index: 100000000;
  
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 26px;
    color: #4cb2f7;
    text-shadow: #47474763 3px 5px 2px;
    letter-spacing: 2px;
    z-index: 100000000;
  
  } 
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }
  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: transform .2s;
  }


  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 50px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 20px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  #fontSize2:hover {
    color: rgb(80, 229, 255);
    text-align: left;
    text-shadow: 0 0 10px #65c2f8;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #00fefe 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(192,46,123,0.5010175945378151) 0%, rgba(6,0,87,0) 41%, rgba(25,18,40,0) 100%), url('./assets/bg3.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .submit:hover {
    transform: scale(1.1);
  }
  
  .emailMain {
    background: rgb(244, 244, 244);
    background: linear-gradient(180deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 1) 35%, rgba(255, 255, 255, 1) 100%);
    padding-top: 50px;
  
  }
  
  .osPic3:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .socialMain {
    color: #0b1016;
    font-size: 16.8px;
    justify-content: flex-start;
    padding: 25px;
    margin-top: -50px;
  }
  
  .socialMainMob {
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
    display: none;
  }
  
  .socialMain2 {
    justify-content: center;
  
  }
  
  
  .osPic3 {
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: transform .2s;
  }
  
  .email {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }
  
  .submit {
    transition: transform .2s;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 25px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
  
  }
  
  .emailCon {
    font-size: 25px;
    text-align: center;
    color: white;
  
  }
  
  .handshake {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 10px;
  }
  
  
  input {
    padding: 10px;
    border: 2px solid black;
    border-radius: 7px;
  }
  
  .connect2Moile {
    display: none;
  }
  
  .iconsMobile {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -5%;
    margin-left: -5%;
    margin-top: -40%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 50px;
    height: 50px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -8%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }
  
  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
  
  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }
  
  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }
  
  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }
  
  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }
  
  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }
  
  
  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
  
  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }
  
  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }
  
  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }
  
  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }
  
  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }
  
  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }
  
  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }
  
  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }
  
  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }
  
  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }
  
  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  
  
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(25,18,40);
    background: linear-gradient(180deg, rgba(25,18,40,1) 0%, rgba(40,97,149,1) 41%, rgba(0,17,33,1) 100%);
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 50px;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12, 17, 19, 0.33575148809523814) 0%, rgba(12, 17, 19, 0.36656381302521013) 35%, rgba(12, 17, 19, 0.7391128326330532) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer img {
    width: 450px;
    height: 116px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }
  
  .sharks {
    width: 100%;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    background-color: #06223583;
    box-shadow: 0 0 20px #23bfe6;
    border: 2px #9fecff solid;
    width: 90%;
    margin-top: 25px;

  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 70px;
    color: white;
    font-family: 'Archivo Black', sans-serif;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    -webkit-text-stroke: 1px #a4dfff;
  
  }
  
  
  
  .ob {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    color: #1a6e2e;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 90px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;
  }
  
  .mintName {
    color: rgb(241, 1, 1);
    font-size: 70px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyCon3 {
    font-size: 22px;
    margin-top: 20px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 15px #27a0f7;
  }
  
  .storyCon2-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 35px;
    margin-top: 20px;
    text-align: center;
    color: black;
  }
  
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 180px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }
  
  .logoF {
    color: white;
    text-align: center;
    font-size: 30px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
  }
  
  .socialMain2 img {
    width: 25%;
    height: 25%;
  }
  
  .intro img {
    width: 40%;
    z-index: 1000000000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    border: 5px solid #c35bd1;
    margin-top: 50px;
    box-shadow: -10px 10px #73347b;
  }
  
.placeAndWL {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
   /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  background: rgba(82, 82, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 30px;
  width: 75%;
  margin-bottom: 10%;

}

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 25px;
    text-shadow: -2.5px 0 red;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;    text-align: center;
    color: white;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 25px;
    text-align: center;
    color: white;
    text-shadow: -2px 0 #ff00c1;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
  }
  
  .success2 {
    font-size: 25px;
    text-align: center;
    color: white;
    margin-top: 1%;
  
  }
  
  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Barlow Condensed', sans-serif;
    color: #00E6F6;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  }
  
  .connect div:hover {
    color: white;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: 2px white solid;
    box-shadow: 0 0 10px #ffffff;
    cursor: pointer;
    background-color: #6cfc1900;
    color: rgba(134, 212, 243, 0.952);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: white;
  
    font-size: 30px;
    text-shadow: -3px 0 #1ee03e;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #102738;
    box-shadow: 0px 5px #1271b0;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    display: none;

  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .button-87 {
    background-color: #00000000;
    border: #ffffff 2px solid;
    font-size: 20px;
    color: #00E6F6;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    padding: 10px;
    width: 200px;
    margin-left: 10px;
    transition: 0.3s ease;
    box-shadow: 0 0 10px #ffffff;
  }
  
  .button-87:hover{
    border: #00E6F6 2px solid;
    color: #ffffff;
    box-shadow: 0 0 10px #23bfe6;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    margin-top: -37px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    cursor: pointer;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #00E6F6;
  }
  
  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 20px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    border: 2px white solid;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 80%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background:  linear-gradient(180deg, rgba(0,17,33,1) 0%, rgba(6,0,87,0.41698398109243695) 41%, rgba(25,18,40,1) 100%), url('./assets/faq.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
     height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  
  }
  
  
  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .vidCont {
    object-fit: cover;
    width: 100%;
  }
  
  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
    */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
  
  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
    z-index: 10000;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
     font-size: 27px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: #90e9f8;
    font-size: 20px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 90%;
    box-shadow: 0 0 10px #23bfe6;
    transition: all 0.3s;
    transform: scale(1);
  }
  
  .man:hover{
    transform: scale(1.1);
  }
  
  .man2:hover{
    transform: scale(1.1);
  }
  
  .man2 {
    width: 100%;  
    transition: all 0.3s;
    transform: scale(1);
  }
  
  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 80px;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
  
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }
  
  .dripCover {
    width: 100%;
  }
  
  .videoBg {
    position: fixed;
    top: 55%;
    left: 55%;
    min-width: 100%;
    min-height: 100%;
    width: 200%;
    height: 200%;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
  }
  
  .mintMain {
    display: flex;
    justify-content: space-between;
    background: rgb(12, 10, 19);
    background: linear-gradient(180deg, rgba(12, 10, 19, 1) 0%, rgba(0, 38, 73, 1) 41%, rgba(0, 17, 33, 1) 100%);
  }
  
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .glitch {
    color: rgb(255, 255, 255);
    font-size: 80px;
    text-transform: upercase;
    position: relative;
    display: inline-block;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  
  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  /* CSS */
  .button-49,
  .button-49:after {
    width: 160px;
    height: 76px;
    line-height: 70px;
    font-size: 25px;
    background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-top: 30px;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
  
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
  
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
  
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
  
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
  
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
  
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
  
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
  
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
  
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
  
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 200px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  
  /* CSS */
  .button-49,
  .button-49:after {
    width: 160px;
    height: 76px;
    line-height: 78px;
    font-size: 25px;
    background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    user-select: none;
    margin-top: 30px;
  
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
  
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
  
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
  
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
  
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
  
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
  
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
  
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
  
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
  
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
  
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 300px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    background: rgb(18,116,181);
    background: linear-gradient(180deg, rgba(18,116,181,1) 0%, rgba(61,68,132,1) 41%, rgba(213,67,139,1) 100%);  padding-left: 100%;
    background-size: contain;
    z-index: 100000000;
  
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 60s;
    z-index: 100000000;
  
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 26px;
    color: #4cb2f7;
    text-shadow: #47474763 3px 5px 2px;
    letter-spacing: 2px;
    z-index: 100000000;
  
  } 
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(192,46,123,0.5010175945378151) 0%, rgba(6,0,87,0) 41%, rgba(25,18,40,0) 100%), url('./assets/bg3.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .submit:hover {
    transform: scale(1.1);
  }
  
  .emailMain {
    background: rgb(244, 244, 244);
    background: linear-gradient(180deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 1) 35%, rgba(255, 255, 255, 1) 100%);
    padding-top: 50px;
  
  }
  
  .osPic3:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .socialMain {
    color: #0b1016;
    font-size: 16.8px;
    justify-content: flex-start;
    padding: 25px;
    margin-top: -50px;
  }
  
  .socialMainMob {
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
    display: none;
  }
  
  .socialMain2 {
    justify-content: center;
  
  }
  
  
  .osPic3 {
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: transform .2s;
  }
  
  .email {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }
  
  .submit {
    transition: transform .2s;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 25px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
  
  }
  
  .emailCon {
    font-size: 25px;
    text-align: center;
    color: white;
  
  }
  
  .handshake {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 10px;
  }
  
  
  input {
    padding: 10px;
    border: 2px solid black;
    border-radius: 7px;
  }
  
  .connect2Moile {
    display: none;
  }
  
  .iconsMobile {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -5%;
    margin-left: -5%;
    margin-top: -40%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 50px;
    height: 50px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }
  
  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
  
  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }
  
  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }
  
  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }
  
  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }
  
  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }
  
  
  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
  
  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }
  
  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }
  
  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }
  
  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }
  
  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }
  
  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }
  
  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }
  
  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }
  
  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }
  
  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }
  
  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  
  
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(25,18,40);
    background: linear-gradient(180deg, rgba(25,18,40,1) 0%, rgba(40,97,149,1) 41%, rgba(0,17,33,1) 100%);
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
  }
    
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 50px;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12, 17, 19, 0.33575148809523814) 0%, rgba(12, 17, 19, 0.36656381302521013) 35%, rgba(12, 17, 19, 0.7391128326330532) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer img {
    width: 450px;
    height: 116px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }
  
  .sharks {
    width: 100%;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    background-color: #06223583;
    box-shadow: 0 0 20px #23bfe6;
    border: 2px #9fecff solid;
    width: 90%;
    margin-top: 25px;

  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 70px;
    color: white;
    font-family: 'Archivo Black', sans-serif;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    -webkit-text-stroke: 1px #a4dfff;
  
  }
  
  
  
  .ob {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    color: #1a6e2e;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 90px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;
  }
  
  .mintName {
    color: rgb(241, 1, 1);
    font-size: 70px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyCon3 {
    font-size: 25px;
    margin-top: 20px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 15px #27a0f7;
  }
  
  .storyCon2-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 35px;
    margin-top: 20px;
    text-align: center;
    color: black;
  }
  
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 180px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }
  
  .logoF {
    color: white;
    text-align: center;
    font-size: 30px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
  }
  
  .socialMain2 img {
    width: 20%;
    height: 20%;
  }
  
  .intro img {
    width: 40%;
    z-index: 1000000000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    border: 5px solid #c35bd1;
    margin-top: 50px;
    box-shadow: -10px 10px #73347b;
  }
  
.placeAndWL {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
   /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  background: rgba(82, 82, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 30px;
  width: 65%;
  margin-bottom: 5%;
}

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 25px;
    text-shadow: -2.5px 0 red;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;    text-align: center;
    color: white;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 25px;
    text-align: center;
    color: white;
    text-shadow: -2px 0 #ff00c1;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
  }
  
  .success2 {
    font-size: 25px;
    text-align: center;
    color: white;
    margin-top: 1%;
  
  }
  
  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Barlow Condensed', sans-serif;
    color: #00E6F6;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  }
  
  .connect div:hover {
    color: white;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: 2px white solid;
    box-shadow: 0 0 10px #ffffff;
    cursor: pointer;
    background-color: #6cfc1900;
    color: rgba(134, 212, 243, 0.952);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: white;
  
    font-size: 30px;
    text-shadow: -3px 0 #1ee03e;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #102738;
    box-shadow: 0px 5px #1271b0;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .button-87 {
    background-color: #00000000;
    border: #ffffff 2px solid;
    font-size: 20px;
    color: #00E6F6;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    padding: 10px;
    width: 200px;
    margin-left: 10px;
    transition: 0.3s ease;
    box-shadow: 0 0 10px #ffffff;
  }
  
  .button-87:hover{
    border: #00E6F6 2px solid;
    color: #ffffff;
    box-shadow: 0 0 10px #23bfe6;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    margin-top: -37px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    cursor: pointer;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #00E6F6;
  }
  
  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 20px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    border: 2px white solid;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 80%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background:  linear-gradient(180deg, rgba(0,17,33,1) 0%, rgba(6,0,87,0.41698398109243695) 41%, rgba(25,18,40,1) 100%), url('./assets/faq.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
     height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  
  }
  
  
  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .vidCont {
    object-fit: cover;
    width: 100%;
  }
  
  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
    */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
  
  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
    z-index: 10000;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
     font-size: 22px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: #90e9f8;
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 90%;
    box-shadow: 0 0 10px #23bfe6;
    transition: all 0.3s;
    transform: scale(1);
  }
  
  .man:hover{
    transform: scale(1.1);
  }
  
  .man2:hover{
    transform: scale(1.1);
  }
  
  .man2 {
    width: 90%;  
    transition: all 0.3s;
    transform: scale(1);
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 25px;
    margin-bottom: 80px;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
  
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }
  
  .dripCover {
    width: 100%;
  }
  
  .videoBg {
    position: fixed;
    top: 55%;
    left: 55%;
    min-width: 100%;
    min-height: 100%;
    width: 200%;
    height: 200%;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
  }
  
  .mintMain {
    display: flex;
    justify-content: space-between;
    background: rgb(12, 10, 19);
    background: linear-gradient(180deg, rgba(12, 10, 19, 1) 0%, rgba(0, 38, 73, 1) 41%, rgba(0, 17, 33, 1) 100%);
  }
  
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .glitch {
    color: rgb(255, 255, 255);
    font-size: 100px;
    text-transform: upercase;
    position: relative;
    display: inline-block;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  
  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  /* CSS */
  .button-49,
  .button-49:after {
    width: 160px;
    height: 76px;
    line-height: 70px;
    font-size: 25px;
    background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-top: 30px;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
  
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
  
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
  
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
  
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
  
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
  
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
  
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
  
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
  
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
  
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 600px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  
  /* CSS */
  .button-49,
  .button-49:after {
    width: 160px;
    height: 76px;
    line-height: 78px;
    font-size: 25px;
    background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    user-select: none;
    margin-top: 30px;
  
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
  
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
  
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
  
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
  
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
  
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
  
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
  
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
  
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
  
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
  
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 300px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background: rgb(18,116,181);
    background: linear-gradient(180deg, rgba(18,116,181,1) 0%, rgba(61,68,132,1) 41%, rgba(213,67,139,1) 100%);  padding-left: 100%;
    background-size: contain;
    z-index: 100000000;
  
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 60s;
    z-index: 100000000;
  
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 30px;
    color: #4cb2f7;
    text-shadow: #47474763 3px 5px 2px;
    letter-spacing: 2px;
    z-index: 100000000;
  
  } 
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

 
  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

.boxWrap2Story2 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(192,46,123,0.5010175945378151) 0%, rgba(6,0,87,0) 41%, rgba(25,18,40,0) 100%), url('./assets/bg3.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.submit:hover {
  transform: scale(1.1);
}

.emailMain {
  background: rgb(244, 244, 244);
  background: linear-gradient(180deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 1) 35%, rgba(255, 255, 255, 1) 100%);
  padding-top: 50px;

}

.osPic3:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.socialMain {
  color: #0b1016;
  font-size: 16.8px;
  justify-content: flex-start;
  padding: 25px;
  margin-top: -50px;
}

.socialMainMob {
  color: #0b1016;
  font-size: 16px;
  justify-content: flex-start;
  padding: 25px;
  display: none;
}

.socialMain2 {
  justify-content: center;

}


.osPic3 {
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  transition: transform .2s;
}

.email {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 20px;
  background-color: #000000f8;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #000000f8;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.email2 {
  flex-flow: column nowrap;
  display: flex;
}

.submit {
  transition: transform .2s;
  width: fit-content;
  background-color: #28a745;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  margin-top: 10px;
  font-size: 25px;
  border-radius: 7px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  color: white;
  padding-left: 15px;
  padding-right: 15px;

}

.emailCon {
  font-size: 25px;
  text-align: center;
  color: white;

}

.handshake {
  width: 35px;
  height: 35px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  margin-right: 10px;
}


input {
  padding: 10px;
  border: 2px solid black;
  border-radius: 7px;
}

.connect2Moile {
  display: none;
}

.iconsMobile {
  display: none;
}

.special:hover {
  background-color: rgb(228, 194, 0);
}

.special:active {
  background-color: rgb(228, 194, 0);
  box-shadow: -0px 7px rgb(153, 131, 4);
  transform: translateY(4px);
}

.special2:hover {
  background-color: rgb(228, 194, 0);

}

.special2:active {
  background-color: rgb(228, 194, 0);
  box-shadow: -0px 7px rgb(153, 131, 4);
  transform: translateY(4px);
}

.stickyBtn {
  position: sticky;
  bottom: -7%;
  margin-left: -5%;
  margin-top: -30%;
  width: 400px;
  height: 400px;
  cursor: pointer;
  z-index: 1;
  animation: floating 2s ease-in-out infinite;
  z-index: 100000;
}

.stickyBtn2 {

  width: 65px;
  height: 65px;

}

#sticky-button {
  position: sticky;
  visibility: hidden;
  position: sticky;
  bottom: 7%;
  margin-left: 90%;
  margin-top: -5%;

  cursor: pointer;
  z-index: 1;
  z-index: 100000;
  transition: transform .4s;
}

.stickyBtn2:hover {
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}



.special {
  font-size: 20px;
  margin-left: 30px;
  border-radius: 10px;
  background-color: gold;
  border: none;
  padding: 10px;
  box-shadow: -0px 5px rgb(194, 165, 5);
}

.special2 {
  font-size: 20px;
  border-radius: 10px;
  background-color: gold;
  border: none;
  padding: 10px;
  box-shadow: -0px 5px rgb(194, 165, 5);
}

.mintingAmount {
  border: black 2px solid;
  border-radius: 10px;
  box-shadow: -0px 5px black;
  padding: 10px;
}

h1 {
  text-align: center;
  margin-bottom: 200px;
  margin-top: 100px;
  font-size: 50px;
  font-weight: 600;
  color: #fe8b00;
}

.timeline-container {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.timeline-container .vertical-scrollable-timeline {
  width: 700px;
  list-style-type: none;
}

.timeline-container .vertical-scrollable-timeline li {
  position: relative;
  padding: 35px 0px 125px 158px;
}

.timeline-container .vertical-scrollable-timeline li:last-child {
  margin-bottom: 0;
}

.timeline-container .vertical-scrollable-timeline li h2 {
  font-size: 45px;
  margin-bottom: 10px;
  color: #fe8b00;
}

.timeline-container .vertical-scrollable-timeline li p {
  line-height: 1.5;
  color: #fff;
  font-size: 20px;
}

.timeline-container .vertical-scrollable-timeline li p:last-child {
  margin-bottom: 0;
}

.timeline-container .vertical-scrollable-timeline li .icon-holder {
  position: absolute;
  left: 0;
  top: 0;
  width: 104px;
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c5c3cb;
  border-radius: 50%;
  z-index: 1;
  transition: 0.4s all;
}

.timeline-container .vertical-scrollable-timeline li .icon-holder::before {
  content: "";
  width: 80px;
  height: 80px;
  border: 4px solid #fff;
  position: absolute;
  background-color: #c5c3cb;
  border-radius: 50%;
  z-index: -1;
  transition: 0.4s all;
}

.timeline-container .vertical-scrollable-timeline li .icon-holder i {
  font-size: 25px;
  color: #fff;
}

.timeline-container .vertical-scrollable-timeline li::after {
  content: "";
  position: absolute;
  height: 0;
  width: 8px;
  background-color: #fe8b00;
  left: 48px;
  top: 34px;
  z-index: 0;
  transition: 0.4s all;
}

.timeline-container .vertical-scrollable-timeline li::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 8px;
  background-color: #c5c3cb;
  left: 48px;
  z-index: 0;
}

.timeline-container .vertical-scrollable-timeline li:last-child::before {
  content: unset;
}

.timeline-container .vertical-scrollable-timeline li.active .icon-holder {
  background-color: #fe8b00;
}

.timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
  background-color: #fe8b00;
}

.timeline-container .vertical-scrollable-timeline li.active::after {
  height: 100%;
}

.timeline-container .vertical-scrollable-timeline li.active:last-child::after {
  content: unset;
}


figure {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

figure:nth-child(1) .rmDetails {
  transform: scale(0.6) rotate(-12.5deg);
}

figure:nth-child(2) .rmDetails {
  transform: scale(0.6) rotate(-9.7222222222deg);
}

figure:nth-child(3) .rmDetails {
  transform: scale(0.6) rotate(-6.9444444444deg);
}

figure:nth-child(4) .rmDetails {
  transform: scale(0.6) rotate(-4.1666666667deg);
}

figure:nth-child(5) .rmDetails {
  transform: scale(0.6) rotate(-1.3888888889deg);
}

figure:nth-child(6) .rmDetails {
  transform: scale(0.6) rotate(1.3888888889deg);
}

figure:nth-child(7) .rmDetails {
  transform: scale(0.6) rotate(4.1666666667deg);
}

figure:nth-child(8) .rmDetails {
  transform: scale(0.6) rotate(6.9444444444deg);
}

figure:nth-child(9) .rmDetails {
  transform: scale(0.6) rotate(9.7222222222deg);
}

figure:nth-child(10) .rmDetails {
  transform: scale(0.6) rotate(12.5deg);
}

figure .rmDetails {
  width: 100%;
  border-radius: 3px;
  background-color: black;
  padding: 50px;
  border: 5px solid gray;
}

.connect2Mobile {
  display: none;
}

.discordNone {
  transition: transform .2s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  cursor: no-drop;
}

.discordNone:hover {
  transform: scale(1.1);
}

.linkedin {
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 10px;
  cursor: pointer;

}

.team1 {
  width: 50%;
}

.discord3 {
  display: none;
}

.discord4 {
  display: none;
}

.storyHRmM {
  display: none;
}

.iconsMob {
  display: none;
}

.mainBanner {
  display: none;
}

.icons {
  display: flex;
  flex-flow: row nowrap;
}

.cover52 {
  display: none;
}

.coverRm {
  width: 100vw;
}

.discordF2 {
  display: none;
}

.connectMobile {
  display: none;
}

.rightMobile {
  display: none;
}




.osPic {
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 10000;
}

.osPic2 {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.price {
  text-align: center;
  font-size: 25px;
  color: white;
}

.discord2 {
  display: none;
}

.discord3 {
  padding-right: 20px;
  display: none;
}


.uRMobile {
  display: none;
}

.storyPicDivMobile {
  display: none;
}

.allWrap {
  background-color: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  contain: content;
}

.boxWrap2 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: black;
}

.boxWrap2Story {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: rgb(238, 238, 238);
  background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
}

.boxWrap2Team {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: rgb(25,18,40);
  background: linear-gradient(180deg, rgba(25,18,40,1) 0%, rgba(40,97,149,1) 41%, rgba(0,17,33,1) 100%);
}

.boxWrap2Mint {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  margin-bottom: 2%;
  position: relative;
  z-index: 1;
}

.boxWrap2Footer {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 50px;
  background-color: rgb(0, 0, 0);
  background: radial-gradient(circle, rgba(12, 17, 19, 0.33575148809523814) 0%, rgba(12, 17, 19, 0.36656381302521013) 35%, rgba(12, 17, 19, 0.7391128326330532) 100%), url('./assets/footer.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap2Footer img {
  width: 450px;
  height: 116px;
  justify-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.boxWrap4 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: black;
}

.boxWrap3 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: #1eccc3;
  margin-bottom: -20%;
}

.sharks {
  width: 100%;
}

.boxWrapC {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  position: relative;

}

.green {
  background-color: #97ba5e;

}

.about {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: row nowrap;
  background-color: #06223583;
  box-shadow: 0 0 20px #23bfe6;
  border: 2px #9fecff solid;
  width: 90%;
  margin-top: 25px;
}

.about2 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 90%;
}

.aboutImg {
  width: 50%;
  border: white 6px solid;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: 3%;
}

.aboutImg2 {
  width: 28%;
  height: 28%;
  border: white 6px solid;
  transition: transform .5s;
}

.aboutImg2:hover {
  transform: scale(1.2);
}

.utilityPics {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.roadmapMain1 {
  top: 0;
  position: relative;
}

.storyPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.UtilDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.storyH {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 70px;
  color: white;
  font-family: 'Archivo Black', sans-serif;
  padding: 10px;
  font-weight: bold;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  -webkit-text-stroke: 1px #a4dfff;

}



.ob {
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
  color: #1a6e2e;
}

.mintH {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
  font-size: 90px;
  background: -webkit-linear-gradient(#43dd67, #165825);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: bold;
  text-align: center;
}

.mintName {
  color: rgb(241, 1, 1);
  font-size: 70px;
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif;
}

.storyRm img {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.tokenH {
  font-size: 70px;
  color: white;

  text-align: center;
  margin-bottom: 2%;
}


.carouselH {
  font-size: 70px;
  color: white;

  text-align: center;
  margin-bottom: 2%;
}

.rmH {
  font-size: 80px;
  color: white;

  text-align: center;
  background-color: #fe8a00;
  padding-left: 20px;
  margin-bottom: 20px;
}

.rmH2 {
  color: grey;
  font-size: 18px;
  line-height: 30px;
}

.rmName {
  background: rgb(81, 190, 229);
  background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
}

.storyCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.storyCon3 {
  font-size: 25px;
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 0 15px #27a0f7;
}

.storyCon2-2 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.storyCon-2 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 50px;
  box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
  background-color: rgba(30, 46, 80, 0.568);

}

.utilCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}


.storyCon2 {
  font-size: 35px;
  margin-top: 20px;
  text-align: center;
  color: black;
}


.storyConP {
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

.storyPic {
  width: 540px;
  height: 339px;
}

.storyRight {
  width: 45%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.uR {
  width: 50%;
}

.intro {
  color: white;
  font-size: 180px;
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: bold;
  text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
}

.logoF {
  color: white;
  text-align: center;
  font-size: 30px;
  background: -webkit-linear-gradient(#43dd67, #28a745);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: bold;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
}

.socialMain2 img {
  width: 15%;
  height: 15%;
}

.intro img {
  width: 40%;
  z-index: 1000000000000000000000;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.intro2 {
  color: rgba(255, 255, 255, 0.705);
  font-size: 20px;
  text-align: center;
  letter-spacing: 5px;
}


.placeAndWL {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
   /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  background: rgba(82, 82, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 30px;
  width: 50%;
  margin-bottom: 3%;

}


.mintDiv {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-bottom: 80px;
  border: 5px solid #c35bd1;
  margin-top: 50px;
  box-shadow: -10px 10px #73347b;
}

.errorMessage {
  margin-left: 3%;
  margin-top: 1%;
  font-size: 25px;
  color: white;
  text-align: center;
  text-shadow: -2.5px 0 red;
  font-family: 'Archivo Black', sans-serif;
  letter-spacing: 2px;
}

.loadingContainer {
  margin-top: 1%;
}

.loadingText {
  font-size: 25px;
  text-align: center;
  color: white;
  text-shadow: -2px 0 #ff00c1;
  font-family: 'Archivo Black', sans-serif;
  letter-spacing: 2px;
}

.success2 {
  font-size: 25px;
  text-align: center;
  color: white;
  margin-top: 1%;

}

.loadTextSub {
  text-align: center;
  color: rgba(255, 255, 255, 0.815);
  font-size: 16px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.wpPic {
  width: 500px;
  height: 500px;
  margin-left: 10%;

}

.wPMobile {
  display: none;
}

.introductionMobile {
  display: none
}

.hl {
  color: #6ce34d;
}

.right {
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-evenly;
  margin-top: auto;
  margin-bottom: auto;
}

.right2 {
  flex-flow: row nowrap;
  display: flex;
  padding-bottom: 1.5%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.discord {
  transition: transform .2s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.discord:hover {
  transform: scale(1.1);
}

.note {
  color: rgb(255, 255, 255);
}

.twitterSoc {
  margin-top: 10px;
  margin-right: 5px;
  /* z-index: -2;*/
  margin-left: 5px;

}

.discordSoc {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  /*  z-index: -2;*/

}

/*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
/*color: #ffffffa2;
}*/
.soc {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

}





.wallet {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 25px;

  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  padding-left: 50px;
  padding-right: 50px;

  border: 2px solid rgb(73, 81, 190);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


}

.wallet:hover {
  background-color: white;
  color: rgb(73, 81, 190);
}

.connect {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: 'Barlow Condensed', sans-serif;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

.connect2 {
  z-index: 1000;
  display: flex;
  flex-flow: row nowrap;
  margin-top: auto;
  margin-bottom: auto;
  font-family: 'Barlow Condensed', sans-serif;
  align-items: center;
}

.connect div {
  margin-left: 10px;
  margin-right: 10px;
  font-family: 'Barlow Condensed', sans-serif;
  color: #00E6F6;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: 0.5s ease;
}

.connect div:hover {
  color: white;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}




.nftblockWalletConnectedALL {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

}

.minting_count_button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.center {
  margin-top: auto;
  margin-bottom: auto;
}

.mintbuttondiv {
  text-align: center;
}

.nftblockWalletConnected {
  display: flex;
  margin-bottom: 10px;
}

.btnfos-0-2 {
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: 2px white solid;
  box-shadow: 0 0 10px #ffffff;
  cursor: pointer;
  background-color: #6cfc1900;
  color: rgba(134, 212, 243, 0.952);
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.btnfos-0-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
}

.btnfos-0-3 {
  margin-top: 20px;
  background-color: white;
  color: #000000;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  transition: transform .2s;
  border: none;

  transition: transform .2s;
}

.btnfos-0-3:hover {
  transform: scale(1.1);
}

.nftamount {
  color: white;
  font-size: 85px;
}

.walletConnect {

  display: flex;
  justify-content: center;
}

.connectButton {

  font-size: 45px;
}

.connectButton:hover {
  color: darkcyan;
  cursor: pointer;

}

.loadingContainer {
  text-align: center;
  padding-top: 20px;
}

.errorMessage {
  text-align: center;
  padding-top: 20px;
}

.successfully {
  text-align: center;
  padding-top: 20px;
  color: white;

  font-size: 30px;
  text-shadow: -3px 0 #1ee03e;
  font-family: 'Archivo Black', sans-serif;
  letter-spacing: 2px;}

.headers {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-top: 15px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #102738;
  box-shadow: 0px 5px #1271b0;
  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.h1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  margin-top: auto;
  margin-bottom: auto;
}

.h2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #5ebaad;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.introduction {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.button-87 {
  background-color: #00000000;
  border: #ffffff 2px solid;
  font-size: 20px;
  color: #00E6F6;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  padding: 10px;
  width: 200px;
  margin-left: 10px;
  transition: 0.3s ease;
  box-shadow: 0 0 10px #ffffff;
}

.button-87:hover{
  border: #00E6F6 2px solid;
  color: #ffffff;
  box-shadow: 0 0 10px #23bfe6;
}

.wallet2:hover {
  color: #000000;
  background-color: #ffffff;
}

.wallet2Btn {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 25px;

  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  width: 400px;
  border: 2px solid #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #2E8B57;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
}

.wallet2Btn:hover {
  color: rgb(156, 156, 156);
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}


.wallet3 {
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  color: rgb(0, 0, 0);
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: black 0px 5px;
}

.wallet3:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.in2 {
  width: 100%;
}

.totalSupply {
  text-align: center;
  font-size: 60px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;

  text-shadow: 0 0 10px #ffffff93;
  letter-spacing: 1px;
  margin-top: 1%;
}

@keyframes Slide_Up {
  0% {
    transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Tag {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Buttons {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

.copyright {
  text-shadow: 0 0 10px #FFFFFF;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  margin-top: -45px;
}

.btn3 {
  flex-flow: row nowrap;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.link {
  cursor: pointer;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes Slide_Left {
  0% {
    transform: translateX(250px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes Slide_Left_Right {

  0%,
  100% {
    transform: translateX(100px);
  }

  12.5%,
  75% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(20px);
  }

}

@keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(127, 212, 48);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.05, 1.05);
  }

  100% {
    transform: scale(1, 1);
  }
}

.logo {
  width: 100px;
  height: 133px;
}

.logo2 {
  width: 20%;
  cursor: pointer;
}

#fontSize {
  font-size: 20px;
  font-family: 'Barlow Condensed', sans-serif;
  cursor: pointer;
  z-index: 10000;
  color: #00E6F6;
}

#fontSize:hover {
  text-shadow: 0 0 10px #FFFFFF;
  color: #ffffff;
}

.parrotDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;

}

.pic1 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 70%;
  bottom: 0;

}

.pic2 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  left: -5%;
  bottom: 0;

}

/*.............. FAQ ..............*/


summary {
  font-size: 20px;
  background: #af3d3300;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 2.5rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  color: white;
  border: 2px white solid;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 70%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.faq__content {

  text-shadow: 0 0 10px #FFFFFF;
  padding-bottom: 20px;
  text-align: left;
  font-size: 18px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;

}

.faqbg {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
   margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: rgb(0, 0, 0);
  background:  linear-gradient(180deg, rgba(0,17,33,1) 0%, rgba(6,0,87,0.41698398109243695) 41%, rgba(25,18,40,1) 100%), url('./assets/faq.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.cover {
  width: 100vw;
}

.cover2 {
  width: 100%;
}

.parrotPic {
  width: 100vw;
}

.parrot {
  width: 70%;
}

.pDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.rmMain {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -100px;
}

.rmBox {
  text-align: right;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox2 {
  text-align: right;
  width: 80%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox {
  font-weight: 700;
  color: rgb(58, 58, 58);
  margin-bottom: 60%;
}

.year {
  font-size: 18px;

  color: rgb(8, 81, 94);
  letter-spacing: 1px;
}


.rmBoxMain2 {
  margin-top: 30%;
  width: 33.33%;
}

.rmBoxMain {
  width: max-content;
  justify-content: flex-start;
  width: 33.33%;

}

.rmBox2 {
  text-align: left;
  width: 30%;
}

.rmParrot {
  width: 130px;
  height: 186px;
  z-index: 1;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
  margin-bottom: 25%;
  margin-right: auto;
  display: block;
  margin-left: 33%;
  margin-top: -50%;
}

.line {
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 270vh;
  margin-top: -30%;
}

.rmP {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 33.33%;
  height: fit-content;
}

.box {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: -15%;
}

.discordF {
  padding-left: 10px;
  padding-right: 10px;
}

.socialAndLogo {
  flex-flow: row nowrap;
  justify-content: space-between;
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 10%;
  padding-top: 10%;
}

.chartAndContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.tCon {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.tCon p {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 13px;
}

.chart {
  width: 60%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.boxWrapToken {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  background: rgb(74, 175, 139);
  background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
}

.tH {
  color: #3b3b3b;
  font-weight: bold;
}

.cont {
   height: 100vh;
  min-height: 100vh;
  height: max-content;
  position: relative;

}


.videoBg2 {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.vidCont {
  object-fit: cover;
  width: 100%;
}

.cont2 {
  /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
  */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;

}

.web {
  width: 100%;
  height: 100%;
}

.cont .drip {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: rgb(24, 70, 28);
  position: absolute;
  top: -100%;
  animation: falling 5s linear infinite;
}

@keyframes falling {
  0% {
    top: -100%;
  }

  50% {
    top: 0%;
  }

  80% {
    top: 80%;
  }

  100% {
    top: 100%;
  }
}

.cont .drip:nth-child(1) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 241px;
  margin-left: 60px;
}

.cont .drip:nth-child(2) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 175px;
  margin-left: 60px;
}

.cont .drip:nth-child(3) {
  border-color: black;
  height: 36px;
  width: 36px;
  animation-delay: -0.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 190px;
  margin-left: 60px;
}

.cont .drip:nth-child(4) {
  border-color: black;
  height: 24px;
  width: 24px;
  animation-delay: -0.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 86px;
  margin-left: 60px;
}

.cont .drip:nth-child(5) {
  border-color: black;
  height: 5px;
  width: 5px;
  animation-delay: -1s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 197px;
  margin-left: 60px;
}

.cont .drip:nth-child(6) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -1.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 244px;
  margin-left: 60px;
}

.cont .drip:nth-child(7) {
  border-color: black;
  height: 11px;
  width: 11px;
  animation-delay: -1.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 120px;
  margin-left: 60px;
}

.cont .drip:nth-child(8) {
  border-color: black;
  height: 10px;
  width: 10px;
  animation-delay: -1.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 120px;
  margin-left: 60px;
}

.cont .drip:nth-child(9) {
  border-color: black;
  height: 27px;
  width: 27px;
  animation-delay: -1.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 29px;
  margin-left: 60px;
}

.cont .drip:nth-child(10) {
  border-color: black;
  height: 33px;
  width: 33px;
  animation-delay: -2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 154px;
  margin-left: 60px;
}

.cont .drip:nth-child(11) {
  border-color: black;
  height: 39px;
  width: 39px;
  animation-delay: -2.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 109px;
  margin-left: 60px;
}

.cont .drip:nth-child(12) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -2.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 272px;
  margin-left: 60px;
}

.cont .drip:nth-child(13) {
  border-color: black;
  height: 13px;
  width: 13px;
  animation-delay: -2.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 41%;
  margin-left: 60px;
}

.cont .drip:nth-child(14) {
  border-color: black;
  height: 21px;
  width: 21px;
  animation-delay: -2.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 71%;
  margin-left: 60px;
}

.cont .drip:nth-child(15) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 95%;
  margin-left: 60px;
}

.cont .drip:nth-child(16) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 76%;
  margin-left: 60px;
}

.cont .drip:nth-child(17) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 71%;
  margin-left: 60px;
}

.cont .drip:nth-child(18) {
  border-color: black;
  height: 38px;
  width: 38px;
  animation-delay: -3.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 46%;
  margin-left: 60px;
}

.cont .drip:nth-child(19) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 41%;
  margin-left: 60px;
}

.cont .drip:nth-child(20) {
  border-color: black;
  height: 19px;
  width: 19px;
  animation-delay: -4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 33%;
  margin-left: 60px;
}


.cont .drip:nth-child(21) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 42%;
  margin-left: 60px;
}

.cont .drip:nth-child(22) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 58%;
  margin-left: 60px;
}

.cont .drip:nth-child(23) {
  border-color: black;
  height: 36px;
  width: 36px;
  animation-delay: -0.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 49%;
  margin-left: 60px;
}

.cont .drip:nth-child(24) {
  border-color: black;
  height: 24px;
  width: 24px;
  animation-delay: -0.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 73%;
  margin-left: 60px;
}

.cont .drip:nth-child(25) {
  border-color: black;
  height: 5px;
  width: 5px;
  animation-delay: -1s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 83%;
  margin-left: 60px;
}

.cont .drip:nth-child(26) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -1.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 28%;
  margin-left: 60px;
}

.cont .drip:nth-child(27) {
  border-color: black;
  height: 11px;
  width: 11px;
  animation-delay: -1.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 14%;
  margin-left: 60px;
}

.cont .drip:nth-child(28) {
  border-color: black;
  height: 10px;
  width: 10px;
  animation-delay: -1.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 43%;
  margin-left: 60px;
}

.cont .drip:nth-child(29) {
  border-color: black;
  height: 27px;
  width: 27px;
  animation-delay: -1.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 68%;
  margin-left: 60px;
}

.cont .drip:nth-child(30) {
  border-color: black;
  height: 33px;
  width: 33px;
  animation-delay: -2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 57%;
  margin-left: 60px;
}

.cont .drip:nth-child(31) {
  border-color: black;
  height: 39px;
  width: 39px;
  animation-delay: -2.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 27%;
  margin-left: 60px;
}

.cont .drip:nth-child(32) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -2.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 12%;
  margin-left: 60px;
}

.cont .drip:nth-child(33) {
  border-color: black;
  height: 13px;
  width: 13px;
  animation-delay: -2.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 30%;
  margin-left: 60px;
}

.cont .drip:nth-child(34) {
  border-color: black;
  height: 21px;
  width: 21px;
  animation-delay: -2.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 38%;
  margin-left: 60px;
}

.cont .drip:nth-child(35) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 55%;
  margin-left: 60px;
}

.cont .drip:nth-child(36) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 50%;
  margin-left: 60px;
}

.cont .drip:nth-child(37) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 58%;
  margin-left: 60px;
}

.cont .drip:nth-child(38) {
  border-color: black;
  height: 38px;
  width: 38px;
  animation-delay: -3.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 67%;
  margin-left: 60px;
}

.cont .drip:nth-child(39) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 70%;
  margin-left: 60px;
}

.cont .drip:nth-child(40) {
  border-color: black;
  height: 19px;
  width: 19px;
  animation-delay: -4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 90%;
  margin-left: 60px;
}

.donutAndInfo {
  display: flex;
  flex-flow: row nowrap;
}

.donut {
  width: 45px;
  height: 45px;
  margin-right: 2%;
}

.rmCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  width: 90%;
}

.phrases p {
  font-size: 18px;
  letter-spacing: 2px;

}

.shoe {
  width: 400px;
  height: 400px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.phrases {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: row nowrap;
  width: fit-content;
  margin-top: 3%;
}

.rmCon1 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.teamCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  flex-flow: column nowrap;

}

.teamCon img {
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  border: 3px solid white;
}

.memberDetails {
  color: white;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.memberName {
  font-size: 23px;
  text-align: center;
}

.memberCon {
  font-size: 20px;
  text-align: center;
}


* {
  box-sizing: border-box;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  width: 100%;
  overflow: hidden;
  height: 4rem;
  background-color: #aa5620;
  padding-left: 100%;
  z-index: 10000;
}

.ticker-wrap .ticker {
  display: inline-block;
  height: 4rem;
  line-height: 4rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 30s;
  animation-duration: 50s;
}

.ticker-wrap .ticker__item {
  display: inline-block;
  padding: 0 1.5rem;
  font-size: 18px;
  color: #ffffff;
  font-weight: 800;
}

.shark {
  width: 400px;
  height: 572px;
  z-index: 1;
  margin-right: -3%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.teamMain {
  display: flex;
}


.memName {
  text-shadow: 0 0 10px #FFFFFF;
  color: #ffffff;
   font-size: 23px;
  text-align: center;
  padding-right: 2%;

}

.memNamePosition {
  color: #90e9f8;
  font-size: 17px;
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.tPic {
  width: 30px;
  height: 30px;
  cursor: pointer;
}


.man {
  width: 90%;
  box-shadow: 0 0 10px #23bfe6;
  transition: all 0.3s;
  transform: scale(1);
}

.man:hover{
  transform: scale(1.1);
}

.man2:hover{
  transform: scale(1.1);
}

.man2 {
  width: 90%;  
  transition: all 0.3s;
  transform: scale(1);
}

.teamSection {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin-top: 25px;
  margin-bottom: 80px;
}

.tPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.subT {

  color: #f9f305;
  font-size: 50px;
  text-align: left;
}

.manDiv {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
  overflow: hidden;
  position: relative;

}

.memName2 {
  color: rgb(255, 255, 255);
  text-align: center;
  font-weight: 500;
  text-shadow: 0 0 10px rgb(255, 255, 255);
}

.rmDetails p {
  color: rgb(209, 209, 209);
  padding-left: 20px;
  font-size: 45px;
}

.flowers {
  width: 100%;
  margin-top: -10%;
}

.rmPicAndCon {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.footPrint2 {
  width: 60px;
  height: 60px;
}


/* Background */
.mainFoot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin-top: -10%;
}

/* Footprint */
.footprint {
  position: absolute;
  top: 20%;
  left: 0;
  animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
  text-align: center;
}

/* Image Size */
.foot {
  max-width: 20%;
}

/* Right Footprint */
.rightFoot {
  margin: 5rem;
  animation-delay: 250ms;
}

/* Walking Animation */
@keyframes walk {
  to {
    transform: translateX(100vw);
  }
}

/* Opacity During Walking */
@keyframes walkopacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 0.4;
  }
}

.honeyComb {
  width: 450px;
  height: 345px;
  position: sticky;
  position: -webkit-sticky;
  left: 80%;
  bottom: 80px;
}

.dripCover {
  width: 100%;
}

.videoBg {
  position: fixed;
  top: 55%;
  left: 55%;
  min-width: 100%;
  min-height: 100%;
  width: 200%;
  height: 200%;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
}

.mintMain {
  display: flex;
  justify-content: space-between;
  background: rgb(12, 10, 19);
  background: linear-gradient(180deg, rgba(12, 10, 19, 1) 0%, rgba(0, 38, 73, 1) 41%, rgba(0, 17, 33, 1) 100%);
}

.glitch-wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Archivo Black', sans-serif;

}

.glitch {
  color: rgb(255, 255, 255);
  font-size: 100px;
  text-transform: upercase;
  position: relative;
  display: inline-block;
  font-family: 'Archivo Black', sans-serif;

}

.glitch::before,
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch::before {
  left: 2px;
  text-shadow: -2px 0 #1ec5fb;
  clip: rect(24px, 550px, 90px, 0);
  -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
}

.glitch::after {
  left: -2px;
  text-shadow: -2px 0 #ba2e79;
  clip: rect(85px, 550px, 140px, 0);
  -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
  animation: glitch-anim 2.5s infinite linear alternate-reverse;
}

@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-o-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-moz-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-webkit-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-o-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-moz-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

/* CSS */
.button-49,
.button-49:after {
  width: 160px;
  height: 76px;
  line-height: 70px;
  font-size: 25px;
  background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  box-shadow: 6px 0px 0px #00E6F6;
  outline: transparent;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 30px;
}

.button-49:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);

  content: 'ALTERNATE TEXT';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
  text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
  clip-path: var(--slice-0);
}

.button-49:hover:after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }

  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }

  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }

  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }

  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }

  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }

  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }

  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }

  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }

  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }

  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}

@media (min-width: 768px) {

  .button-49,
  .button-49:after {
    width: 200px;
    height: 86px;
    line-height: 88px;
  }
}


/* CSS */
.button-49,
.button-49:after {
  width: 160px;
  height: 76px;
  line-height: 78px;
  font-size: 25px;
  background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  box-shadow: 6px 0px 0px #00E6F6;
  outline: transparent;
  position: relative;
  user-select: none;
  margin-top: 30px;

  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-49:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);

  content: 'ALTERNATE TEXT';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
  text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
  clip-path: var(--slice-0);
}

.button-49:hover:after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }

  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }

  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }

  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }

  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }

  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }

  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }

  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }

  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }

  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }

  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}

@media (min-width: 768px) {

  .button-49,
  .button-49:after {
    width: 300px;
    height: 86px;
    line-height: 88px;
  }
}

.ticker-wrap {
  width: 100%;
  overflow: hidden;
  height: 6rem;
  background: rgb(18,116,181);
  background: linear-gradient(180deg, rgba(18,116,181,1) 0%, rgba(61,68,132,1) 41%, rgba(213,67,139,1) 100%);  padding-left: 100%;
  background-size: contain;
  z-index: 100000000;

}

.ticker-wrap .ticker {
  display: inline-block;
  height: 6rem;
  line-height: 6rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 30s;
  animation-duration: 60s;
  z-index: 100000000;

}

.ticker-wrap .ticker__item {
  display: inline-block;
  padding: 0 1.5rem;
  font-size: 30px;
  color: #4cb2f7;
  text-shadow: #47474763 3px 5px 2px;
  letter-spacing: 2px;
  z-index: 100000000;

} 
}

@media screen and (min-width: 1920px) {
  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(192,46,123,0.5010175945378151) 0%, rgba(6,0,87,0) 41%, rgba(25,18,40,0) 100%), url('./assets/bg3.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .submit:hover {
    transform: scale(1.1);
  }
  
  .emailMain {
    background: rgb(244, 244, 244);
    background: linear-gradient(180deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 1) 35%, rgba(255, 255, 255, 1) 100%);
    padding-top: 50px;
  
  }
  
  .osPic3:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .socialMain {
    color: #0b1016;
    font-size: 16.8px;
    justify-content: flex-start;
    padding: 25px;
    margin-top: -50px;
  }
  
  .socialMainMob {
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
    display: none;
  }
  
  .socialMain2 {
    justify-content: center;
  
  }
  
  
  .osPic3 {
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: transform .2s;
  }
  
  .email {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }
  
  .submit {
    transition: transform .2s;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 25px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
  
  }
  
  .emailCon {
    font-size: 25px;
    text-align: center;
    color: white;
  
  }
  
  .handshake {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 10px;
  }
  
  
  input {
    padding: 10px;
    border: 2px solid black;
    border-radius: 7px;
  }
  
  .connect2Moile {
    display: none;
  }
  
  .iconsMobile {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 85px;
    height: 85px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }
  
  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
  
  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }
  
  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }
  
  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }
  
  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }
  
  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }
  
  
  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
  
  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }
  
  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }
  
  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }
  
  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }
  
  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }
  
  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }
  
  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }
  
  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }
  
  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }
  
  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }
  
  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  
  
  
  .osPic {
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 30px;
    color: white;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(25,18,40);
    background: linear-gradient(180deg, rgba(25,18,40,1) 0%, rgba(40,97,149,1) 41%, rgba(0,17,33,1) 100%);
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
  }
    
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 50px;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12, 17, 19, 0.33575148809523814) 0%, rgba(12, 17, 19, 0.36656381302521013) 35%, rgba(12, 17, 19, 0.7391128326330532) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer img {
    width: 450px;
    height: 116px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }
  
  .sharks {
    width: 100%;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    background-color: #06223583;
    box-shadow: 0 0 20px #23bfe6;
    border: 2px #9fecff solid;
    width: 90%;
    margin-top: 25px;

  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 70px;
    color: white;
    font-family: 'Archivo Black', sans-serif;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    -webkit-text-stroke: 1px #a4dfff;
  
  }
  
  
  
  .ob {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    color: #1a6e2e;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 90px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;
  }
  
  .mintName {
    color: rgb(241, 1, 1);
    font-size: 70px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyCon3 {
    font-size: 35px;
    margin-top: 20px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 15px #27a0f7;
  }
  
  .storyCon2-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 35px;
    margin-top: 20px;
    text-align: center;
    color: black;
  }
  
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 180px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }
  
  .logoF {
    color: white;
    text-align: center;
    font-size: 30px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
  }
  
  .socialMain2 img {
    width: 15%;
    height: 15%;
  }
  
  .intro img {
    width: 40%;
    z-index: 1000000000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    border: 5px solid #c35bd1;
    margin-top: 50px;
    box-shadow: -10px 10px #73347b;
  }

  
.placeAndWL {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
   /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  background: rgba(82, 82, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 2px solid rgba(255, 255, 255, 0.3);
  padding: 30px;
  width: 50%;
  margin-bottom: 3%;

}
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 33px;
    text-shadow: -2.5px 0 red;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
    text-align: center;
    color: white;
  }
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 36px;
    text-align: center;
    color: white;
    text-shadow: -2px 0 #ff00c1;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
  }
  
  .success2 {
    font-size: 25px;
    text-align: center;
    color: white;
    margin-top: 1%;
  
  }
  
  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Barlow Condensed', sans-serif;
    color: #00E6F6;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  }
  
  .connect div:hover {
    color: white;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 5px;
    width: 80px;
    height: 80px;
    border: 2px white solid;
    box-shadow: 0 0 10px #ffffff;
    cursor: pointer;
    background-color: #6cfc1900;
    color: rgba(134, 212, 243, 0.952);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 95px;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: white;
    text-shadow: -3px 0 #1ee03e;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
    font-size: 50px;
   }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #102738;
    box-shadow: 0px 7px #1271b0;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .button-87 {
    background-color: #00000000;
    border: #ffffff 2px solid;
    font-size: 27px;
    color: #00E6F6;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    padding: 13px;
    width: 250px;
    margin-left: 10px;
    transition: 0.3s ease;
    box-shadow: 0 0 10px #ffffff;
  }
  
  .button-87:hover{
    border: #00E6F6 2px solid;
    color: #ffffff;
    box-shadow: 0 0 10px #23bfe6;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
    text-align: center;
    font-size: 25px;
    margin-top: -45px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    cursor: pointer;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 27px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #00E6F6;
  }
  
  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 30px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    border: 3px white solid;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 27px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background:  linear-gradient(180deg, rgba(0,17,33,1) 0%, rgba(6,0,87,0.41698398109243695) 41%, rgba(25,18,40,1) 100%), url('./assets/faq.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
     height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  
  }
  
  
  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .vidCont {
    object-fit: cover;
    width: 100%;
  }
  
  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
    */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
  
  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
    z-index: 10000;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
     font-size: 33px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: #90e9f8;
    font-size: 25px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 90%;
    box-shadow: 0 0 10px #23bfe6;
    transition: all 0.3s;
    transform: scale(1);
  }
  
  .man:hover{
    transform: scale(1.1);
  }
  
  .man2:hover{
    transform: scale(1.1);
  }
  
  .man2 {
    width: 90%;  
    transition: all 0.3s;
    transform: scale(1);
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 35px;
    margin-bottom: 80px;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
  
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }
  
  .dripCover {
    width: 100%;
  }
  
  .videoBg {
    position: fixed;
    top: 55%;
    left: 55%;
    min-width: 100%;
    min-height: 100%;
    width: 200%;
    height: 200%;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
  }
  
  .mintMain {
    display: flex;
    justify-content: space-between;
    background: rgb(12, 10, 19);
    background: linear-gradient(180deg, rgba(12, 10, 19, 1) 0%, rgba(0, 38, 73, 1) 41%, rgba(0, 17, 33, 1) 100%);
  }
  
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .glitch {
    color: rgb(255, 255, 255);
    font-size: 135px;
    text-transform: upercase;
    position: relative;
    display: inline-block;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  
  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  /* CSS */
  .button-49,
  .button-49:after {
    width: 160px;
    height: 76px;
    line-height: 70px;
    font-size: 25px;
    background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-top: 30px;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
  
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
  
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
  
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
  
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
  
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
  
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
  
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
  
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
  
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
  
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 600px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  
  /* CSS */
  .button-49,
  .button-49:after {
    width: 600px;
    height: 76px;
    line-height: 78px;
    font-size: 35px;
    background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    user-select: none;
    margin-top: 30px;
  
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
  
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
  
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
  
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
  
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
  
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
  
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
  
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
  
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
  
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
  
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 400px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 7.5rem;
    background: rgb(18,116,181);
    background: linear-gradient(180deg, rgba(18,116,181,1) 0%, rgba(61,68,132,1) 41%, rgba(213,67,139,1) 100%);  padding-left: 100%;
    background-size: contain;
    z-index: 100000000;
  
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 7.5rem;
    line-height: 7.5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 60s;
    z-index: 100000000;
  
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 39px;
    color: #4cb2f7;
    text-shadow: #47474763 3px 5px 2px;
    letter-spacing: 2px;
    z-index: 100000000;
  
  } 
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(192,46,123,0.5010175945378151) 0%, rgba(6,0,87,0) 41%, rgba(25,18,40,0) 100%), url('./assets/bg3.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .submit:hover {
    transform: scale(1.1);
  }
  
  .emailMain {
    background: rgb(244, 244, 244);
    background: linear-gradient(180deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 1) 35%, rgba(255, 255, 255, 1) 100%);
    padding-top: 50px;
  
  }
  
  .osPic3:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .socialMain {
    color: #0b1016;
    font-size: 16.8px;
    justify-content: flex-start;
    padding: 25px;
    margin-top: -50px;
  }
  
  .socialMainMob {
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
    display: none;
  }
  
  .socialMain2 {
    justify-content: center;
  
  }
  
  
  .osPic3 {
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: transform .2s;
  }
  
  .email {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }
  
  .submit {
    transition: transform .2s;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 25px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
  
  }
  
  .emailCon {
    font-size: 25px;
    text-align: center;
    color: white;
  
  }
  
  .handshake {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 10px;
  }
  
  
  input {
    padding: 10px;
    border: 2px solid black;
    border-radius: 7px;
  }
  
  .connect2Moile {
    display: none;
  }
  
  .iconsMobile {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 110px;
    height: 110px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }
  
  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
  
  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }
  
  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }
  
  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }
  
  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }
  
  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }
  
  
  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
  
  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }
  
  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }
  
  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }
  
  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }
  
  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }
  
  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }
  
  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }
  
  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }
  
  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }
  
  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }
  
  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }

  .osPic {
    width: 55px;
    height: 55px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 40px;
    color: white;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(25,18,40);
    background: linear-gradient(180deg, rgba(25,18,40,1) 0%, rgba(40,97,149,1) 41%, rgba(0,17,33,1) 100%);
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
  }
 
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 50px;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12, 17, 19, 0.33575148809523814) 0%, rgba(12, 17, 19, 0.36656381302521013) 35%, rgba(12, 17, 19, 0.7391128326330532) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer img {
    width: 450px;
    height: 116px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }
  
  .sharks {
    width: 100%;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    background-color: #06223583;
    box-shadow: 0 0 20px #23bfe6;
    border: 2px #9fecff solid;
    width: 90%;
    margin-top: 25px;

  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 70px;
    color: white;
    font-family: 'Archivo Black', sans-serif;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    -webkit-text-stroke: 1px #a4dfff;
  
  }
  
  
  
  .ob {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    color: #1a6e2e;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 90px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;
  }
  
  .mintName {
    color: rgb(241, 1, 1);
    font-size: 70px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyCon3 {
    font-size: 47px;
    margin-top: 20px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 15px #27a0f7;
  }
  
  .storyCon2-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 35px;
    margin-top: 20px;
    text-align: center;
    color: black;
  }
  
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 180px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }
  
  .logoF {
    color: white;
    text-align: center;
    font-size: 30px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
  }
  
  .socialMain2 img {
    width: 15%;
    height: 15%;
  }
  
  .intro img {
    width: 40%;
    z-index: 1000000000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    border: 10px solid #c35bd1;
    margin-top: 50px;
    box-shadow: -20px 20px #73347b;
  }
  
.placeAndWL {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
   /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  background: rgba(82, 82, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 3px solid rgba(255, 255, 255, 0.3);
  padding: 30px;
  width: 50%;
  margin-bottom: 3%;

}

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 50px;
    text-shadow: -3px 0 red;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
    text-align: center;
    color: white;
  }
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 46px;
    text-align: center;
    color: white;
    text-shadow: -2px 0 #ff00c1;
  font-family: 'Archivo Black', sans-serif;
  letter-spacing: 2px;
  }
  
  .success2 {
    font-size: 25px;
    text-align: center;
    color: white;
    margin-top: 1%;
  
  }
  
  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
  }
  
  .connect div {
    margin-left: 20px;
    margin-right: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    color: #00E6F6;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  }
  
  .connect div:hover {
    color: white;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 5px;
    width: 120px;
    height: 120px;
    border: 2px white solid;
    box-shadow: 0 0 10px #ffffff;
    cursor: pointer;
    background-color: #6cfc1900;
    color: rgba(134, 212, 243, 0.952);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 130px;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: white;
  
    font-size: 60px;
    text-shadow: -3px 0 #1ee03e;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #102738;
    box-shadow: 0px 10px #1271b0;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .button-87 {
    background-color: #00000000;
    border: #ffffff 2px solid;
    font-size: 37px;
    color: #00E6F6;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    padding: 18px;
    width: 350px;
    margin-left: 20px;
    transition: 0.3s ease;
    box-shadow: 0 0 10px #ffffff;
  }
  
  .button-87:hover{
    border: #00E6F6 2px solid;
    color: #ffffff;
    box-shadow: 0 0 10px #23bfe6;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 120px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
    text-align: center;
    font-size: 35px;
    margin-top: -45px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    cursor: pointer;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 37px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #00E6F6;
  }
  
  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 40px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    border: 3px white solid;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 33px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background:  linear-gradient(180deg, rgba(0,17,33,1) 0%, rgba(6,0,87,0.41698398109243695) 41%, rgba(25,18,40,1) 100%), url('./assets/faq.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
     height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  
  }
  
  
  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .vidCont {
    object-fit: cover;
    width: 100%;
  }
  
  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
    */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
  
  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
    z-index: 10000;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
     font-size: 42px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: #90e9f8;
    font-size: 33px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 90%;
    box-shadow: 0 0 10px #23bfe6;
    transition: all 0.3s;
    transform: scale(1);
  }
  
  .man:hover{
    transform: scale(1.1);
  }
  
  .man2:hover{
    transform: scale(1.1);
  }
  
  .man2 {
    width: 90%;  
    transition: all 0.3s;
    transform: scale(1);
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 35px;
    margin-bottom: 80px;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
  
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }
  
  .dripCover {
    width: 100%;
  }
  
  .videoBg {
    position: fixed;
    top: 55%;
    left: 55%;
    min-width: 100%;
    min-height: 100%;
    width: 200%;
    height: 200%;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
  }
  
  .mintMain {
    display: flex;
    justify-content: space-between;
    background: rgb(12, 10, 19);
    background: linear-gradient(180deg, rgba(12, 10, 19, 1) 0%, rgba(0, 38, 73, 1) 41%, rgba(0, 17, 33, 1) 100%);
  }
  
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .glitch {
    color: rgb(255, 255, 255);
    font-size: 170px;
    text-transform: upercase;
    position: relative;
    display: inline-block;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitch::before {
    left: 3.3px;
    text-shadow: -3.3px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitch::after {
    left: -3.3px;
    text-shadow: -3.3px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  
  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  /* CSS */
  .button-49,
  .button-49:after {
    width: 500px;
    height: 76px;
    line-height: 70px;
    font-size: 25px;
    background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-top: 30px;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
  
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
  
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
  
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
  
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
  
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
  
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
  
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
  
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
  
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
  
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 500px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  
  /* CSS */
  .button-49,
  .button-49:after {
    width: 160px;
    height: 76px;
    line-height: 78px;
    font-size: 45px;
    background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    user-select: none;
    margin-top: 30px;
  
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
  
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
  
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
  
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
  
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
  
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
  
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
  
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
  
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
  
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
  
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 600px;
      height: 140px;
      line-height: 88px;
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 9rem;
    background: rgb(18,116,181);
    background: linear-gradient(180deg, rgba(18,116,181,1) 0%, rgba(61,68,132,1) 41%, rgba(213,67,139,1) 100%);  padding-left: 100%;
    background-size: contain;
    z-index: 100000000;
  
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 9rem;
    line-height: 9rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 60s;
    z-index: 100000000;
  
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 53px;
    color: #4cb2f7;
    text-shadow: #47474763 3px 5px 2px;
    letter-spacing: 2px;
    z-index: 100000000;
  
  } 
}

@media screen and (min-width: 3840px) {
  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(192,46,123,0.5010175945378151) 0%, rgba(6,0,87,0) 41%, rgba(25,18,40,0) 100%), url('./assets/bg3.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .submit:hover {
    transform: scale(1.1);
  }
  
  .emailMain {
    background: rgb(244, 244, 244);
    background: linear-gradient(180deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 1) 35%, rgba(255, 255, 255, 1) 100%);
    padding-top: 50px;
  
  }
  
  .osPic3:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .socialMain {
    color: #0b1016;
    font-size: 16.8px;
    justify-content: flex-start;
    padding: 25px;
    margin-top: -50px;
  }
  
  .socialMainMob {
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
    display: none;
  }
  
  .socialMain2 {
    justify-content: center;
  
  }
  
  
  .osPic3 {
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: transform .2s;
  }
  
  .email {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }
  
  .submit {
    transition: transform .2s;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 25px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
  
  }
  
  .emailCon {
    font-size: 25px;
    text-align: center;
    color: white;
  
  }
  
  .handshake {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 10px;
  }
  
  
  input {
    padding: 10px;
    border: 2px solid black;
    border-radius: 7px;
  }
  
  .connect2Moile {
    display: none;
  }
  
  .iconsMobile {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 160px;
    height: 160px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }
  
  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
  
  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }
  
  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }
  
  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }
  
  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }
  
  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }
  
  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }
  
  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }
  
  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }
  
  
  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
  
  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }
  
  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }
  
  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }
  
  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }
  
  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }
  
  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }
  
  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }
  
  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }
  
  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }
  
  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }
  
  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }

  .osPic {
    width: 78px;
    height: 78px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 60px;
    color: white;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(25,18,40);
    background: linear-gradient(180deg, rgba(25,18,40,1) 0%, rgba(40,97,149,1) 41%, rgba(0,17,33,1) 100%);
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
  }  
 
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 50px;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12, 17, 19, 0.33575148809523814) 0%, rgba(12, 17, 19, 0.36656381302521013) 35%, rgba(12, 17, 19, 0.7391128326330532) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer img {
    width: 450px;
    height: 116px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }
  
  .sharks {
    width: 100%;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    background-color: #06223583;
    box-shadow: 0 0 20px #23bfe6;
    border: 2px #9fecff solid;
    width: 90%;
    margin-top: 25px;

  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 70px;
    color: white;
    font-family: 'Archivo Black', sans-serif;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    -webkit-text-stroke: 1px #a4dfff;
  
  }
  
  
  
  .ob {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    color: #1a6e2e;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 90px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;
  }
  
  .mintName {
    color: rgb(241, 1, 1);
    font-size: 70px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyCon3 {
    font-size: 70px;
    margin-top: 20px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 15px #27a0f7;
  }
  
  .storyCon2-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 35px;
    margin-top: 20px;
    text-align: center;
    color: black;
  }
  
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 180px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }
  
  .logoF {
    color: white;
    text-align: center;
    font-size: 30px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
  }
  
  .socialMain2 img {
    width: 15%;
    height: 15%;
  }
  
  .intro img {
    width: 40%;
    z-index: 1000000000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 80px;
    border: 10px solid #c35bd1;
    margin-top: 50px;
    box-shadow: -20px 20px #73347b;
  }
  
.placeAndWL {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
   /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  background: rgba(82, 82, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 4px solid rgba(255, 255, 255, 0.3);
  padding: 30px;
  width: 50%;
}

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 70px;
    text-shadow: -2.5px 0 red;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;    text-align: center;
    color: white;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 46px;
    text-align: center;
    color: white;
    text-shadow: -2px 0 #ff00c1;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
  }
  
  .success2 {
    font-size: 25px;
    text-align: center;
    color: white;
    margin-top: 1%;
  
  }
  
  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 35px;
    padding-right: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
  }
  
  .connect div {
    margin-left: 30px;
    margin-right: 30px;
    font-family: 'Barlow Condensed', sans-serif;
    color: #00E6F6;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  }
  
  .connect div:hover {
    color: white;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 5px;
    width: 150px;
    height: 150px;
    border: 2px white solid;
    box-shadow: 0 0 10px #ffffff;
    cursor: pointer;
    background-color: #6cfc1900;
    color: rgba(134, 212, 243, 0.952);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 180px;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: white;
  
    font-size: 60px;
    text-shadow: -3px 0 #1ee03e;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 30px;
    padding-top: 30px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #102738;
    box-shadow: 0px 15px #1271b0;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .button-87 {
    background-color: #00000000;
    border: #ffffff 2px solid;
    font-size: 54px;
    color: #00E6F6;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    padding: 23px;
    width: 500px;
    margin-left: 30px;
    transition: 0.3s ease;
    box-shadow: 0 0 10px #ffffff;
  }
  
  .button-87:hover{
    border: #00E6F6 2px solid;
    color: #ffffff;
    box-shadow: 0 0 10px #23bfe6;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 170px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
    text-align: center;
    font-size: 45px;
    margin-top: -80px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    cursor: pointer;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 54px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #00E6F6;
  }
  
  #fontSize:hover {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 50px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1.8rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    border: 3px white solid;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 43px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background:  linear-gradient(180deg, rgba(0,17,33,1) 0%, rgba(6,0,87,0.41698398109243695) 41%, rgba(25,18,40,1) 100%), url('./assets/faq.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
     height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  
  }
  
  
  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .vidCont {
    object-fit: cover;
    width: 100%;
  }
  
  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
    */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
  
  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
    z-index: 10000;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
    text-shadow: 0 0 10px #FFFFFF;
    color: #ffffff;
     font-size: 55px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: #90e9f8;
    font-size: 50px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 90%;
    box-shadow: 0 0 10px #23bfe6;
    transition: all 0.3s;
    transform: scale(1);
  }
  
  .man:hover{
    transform: scale(1.1);
  }
  
  .man2:hover{
    transform: scale(1.1);
  }
  
  .man2 {
    width: 90%;  
    transition: all 0.3s;
    transform: scale(1);
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 35px;
    margin-bottom: 80px;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
  
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }
  
  .dripCover {
    width: 100%;
  }
  
  .videoBg {
    position: fixed;
    top: 55%;
    left: 55%;
    min-width: 100%;
    min-height: 100%;
    width: 200%;
    height: 200%;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
  }
  
  .mintMain {
    display: flex;
    justify-content: space-between;
    background: rgb(12, 10, 19);
    background: linear-gradient(180deg, rgba(12, 10, 19, 1) 0%, rgba(0, 38, 73, 1) 41%, rgba(0, 17, 33, 1) 100%);
  }
  
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .glitch {
    color: rgb(255, 255, 255);
    font-size: 250px;
    text-transform: upercase;
    position: relative;
    display: inline-block;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitch::before {
    left: 6px;
    text-shadow: -6px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitch::after {
    left: -6px;
    text-shadow: -6px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  
  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  /* CSS */
  .button-49,
  .button-49:after {
    width: 160px;
    height: 76px;
    line-height: 70px;
    font-size: 25px;
    background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-top: 30px;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
  
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
  
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
  
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
  
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
  
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
  
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
  
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
  
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
  
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
  
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 600px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  
  /* CSS */
  .button-49,
  .button-49:after {
    width: 160px;
    height: 76px;
    line-height: 78px;
    font-size: 70px;
    background: linear-gradient(45deg, transparent 5%, #d23c86 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    user-select: none;
    margin-top: 30px;
  
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #d23c86 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
  
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
  
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
  
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
  
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
  
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
  
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
  
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
  
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
  
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
  
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 600px;
      height: 190px;
      line-height: 88px;
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 11rem;
    background: rgb(18,116,181);
    background: linear-gradient(180deg, rgba(18,116,181,1) 0%, rgba(61,68,132,1) 41%, rgba(213,67,139,1) 100%);  padding-left: 100%;
    background-size: contain;
    z-index: 100000000;
  
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 11rem;
    line-height: 11rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 60s;
    z-index: 100000000;
  
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 3rem;
    font-size: 65px;
    color: #4cb2f7;
    text-shadow: #47474763 3px 5px 2px;
    letter-spacing: 2px;
    z-index: 100000000;
  
  } 
}